import {
    createStore
} from 'vuex'
import leads from './modules/leads';
import contacts from './modules/contacts';
import tasks from './modules/tasks';
import viewings from './modules/viewings';
import notifications from './modules/notifications';
import referrals from './modules/referrals';
import dashboard from './modules/dashboard';


export default createStore({
    modules: {
        leads,
        contacts,
        tasks,
        viewings,
        notifications,
        referrals,
        dashboard,
    }
});