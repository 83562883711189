// import {
//     propsToAttrMap
// } from "@vue/shared";
import axios from "axios";
import DBCRM from "../../assets/scripts/indexDB/config.js";

const state = {
  contacts: [],
  focusContactDetails: [],
  focusContactId: null,
  focusContactEnquiriesList: [],
  setSearchContact: [],
};
const getters = {
  allContacts: (state) => state.contacts,
  focusContactDetails: (state) => state.focusContactDetails,
  focusContactEnquiries: (state) => state.focusContactEnquiriesList,
  getSearchContact: (state) => state.setSearchContact,
};
const actions = {
  syncContacts: async ({ commit }, ids) => {
    let user_id = ids.user;
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let response = await axios.get(`/api/v1/contacts/${user_id}`);

    if (response.data.data.legnth > 0) {
      await dbcrm.contacts.empty();
    }
    for (let contact of response.data.data) {
      let local = await dbcrm.contacts.get({
        pk_id: contact.id,
      });

      if (local == undefined) {
        dbcrm.contacts
          .add({
            pk_id: contact.id,
            introduction_agent: contact.introduction_agent,
            created: contact.created,
            modified: contact.modified,
            source: contact.source,
            status: contact.status,
            legal_basis: contact.legal_basis,
            first_name: contact.first_name,
            last_name: contact.last_name,
            email: contact.email,
            cell_number: contact.cell_number,
            home_number: contact.home_number,
            work_number: contact.work_number,
            postal_address: contact.postal_address,
            physical_address: contact.physical_address,
            birthday: contact.birthday,
            contact_types: contact.contact_types,
            id_number: contact.id_number,
            branch: contact.branch,
            active: contact.active,
          })
          .catch((error) => {
            console.error("Adding leads error : ", error);
          });
      }
      if (local) {
        dbcrm.contacts.where("pk_id").equals(contact.id).modify({
          pk_id: contact.id,
          introduction_agent: contact.introduction_agent,
          created: contact.created,
          modified: contact.modified,
          source: contact.source,
          status: contact.status,
          legal_basis: contact.legal_basis,
          first_name: contact.first_name,
          last_name: contact.last_name,
          email: contact.email,
          cell_number: contact.cell_number,
          home_number: contact.home_number,
          work_number: contact.work_number,
          postal_address: contact.postal_address,
          physical_address: contact.physical_address,
          birthday: contact.birthday,
          contact_types: contact.contact_types,
          id_number: contact.id_number,
          branch: contact.branch,
          active: contact.active,
        });
      }
      let comments = await axios.get(
        `/api/v1/comments/contacts/${contact.id}/`
      );
      for (let comment of comments.data.data) {
        let localcomment = await dbcrm.history.get({
          comments_pk_id: comment.comments_pk_id,
        });
        if (localcomment == undefined) {
          let newComment = await dbcrm.history
            .add({
              comments_pk_id: comment.comments_pk_id,
              leads_fk_id: comment.leads_fk_id,
              contacts_fk_id: comment.contacts_fk_id,
              type: comment.type,
              title: comment.title,
              description: comment.description,
              author_email: comment.author_email,
              author_id: comment.author_id,
              created_date: comment.created_date,
              modified_date: comment.modified_date,
              deleted: comment.deleted,
              author: comment.author,
            })
            .catch((error) => console.error("Exception : ", error));
        }
        if (localcomment) {
          let newComment = await dbcrm.history
            .where("comments_pk_id")
            .equals(comment.comments_pk_id)
            .modify({
              comments_pk_id: comment.comments_pk_id,
              leads_fk_id: comment.leads_fk_id,
              contacts_fk_id: comment.contacts_fk_id,
              type: comment.type,
              title: comment.title,
              description: comment.description,
              author_email: comment.author_email,
              author_id: comment.author_id,
              created_date: comment.created_date,
              modified_date: comment.modified_date,
              deleted: comment.deleted,
              author: comment.author,
            })
            .catch((error) => console.error("Exception : ", error));
        }
      }
    }
    await dbcrm.contacts
      .where("active")
      .equals("0")
      .delete()
      .then((res) => {
        // console.log("Result : ", res);
      })
      .catch((error) => {
        console.error("Exception : ", error);
      });
    actions.fetchContacts(
      {
        commit,
      },
      ids.user
    );
  },
  fetchContacts: async ({ commit }, user_id) => {
    let data = [];
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    // console.log("000----000");
    // console.log("typeof userr_id", typeof user_id);
    // console.log("user_id", user_id);
    let response = await dbcrm.contacts
      .where("introduction_agent")
      .equals(user_id)
      .offset(state.contacts.length)
      .limit(10)
      .reverse()
      .sortBy("created");
    data = [...state.contacts, ...response];
    commit("setContacts", data);
  },
  createContact: async ({ commit }, newLead) => {
    if (window.navigator.onLine) {
      const response = await axios.post("/api/v1/leads/create/", newLead);
      newLead.leads_pk_id = response.data.data.id;
    }
    if (!window.navigator.onLine) {
    }
    commit("newLead", newLead);
  },
  mapContacts: (list) => {
    const contactsMap = list.map((contact) => [
      `${contact.first_name} ${contact.last_name} ${contact.email} ${contact.cell_number}`.toLowerCase(),
      contact,
    ]);
    return new Map(contactsMap);
  },
  filterContacts: async ({ commit }, params) => {
    let data = [];
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    if (params == undefined) {
      commit("setContacts", data);
      return;
    }
    if (params.string.length >= 3) {
      try {
        let response = await dbcrm.contacts
          .where("introduction_agent")
          .equals(params.user_id)
          .toArray();
        let newMapRes = actions.mapContacts(response);
        let string = `*${params.string}*`.toLowerCase();
        for (let key of newMapRes.keys()) {
          if (new RegExp("^" + string.replace(/\*/g, ".*") + "$").test(key)) {
            data.push(newMapRes.get(`${key}`.toLowerCase()));
          }
        }
      } catch (error) {
        console.error("Exception : ", error);
      }
      commit("setContacts", data);
      return;
    }
    if (params.string.length == 0 || params.string.replace(" ", "") == "") {
      data = await dbcrm.contacts
        .where("introduction_agent")
        .equals(params.user_id)
        .offset(0)
        .limit(20)
        .reverse()
        .sortBy("created");
      commit("setContacts", data);
      return;
    }
  },
  fetchFocusContact: async ({ commit }) => {
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let contact = await dbcrm.contacts.get({
      pk_id: state.focusContactId,
    });
    commit("setFocusContact", contact);
  },
  fetchFocusContactId: async ({ commit }, id) => {
    commit("setFocusContactId", id);
    // console.log("actions.fetchContactEnquiries(id): ", id);
    actions.fetchContactEnquiries(
      {
        commit,
      },
      id
    );
  },
  fetchContactEnquiries: async ({ commit }, contact_id) => {
    let data = [];
    let response = [];
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    if (window.navigator.onLine) {
      let response = await axios.get(
        `/api/v1/contacts/enquiries/${contact_id}`
      );
      data = response.data.data;
    }
    // if (!window.navigator.onLine)
    // response = await dbcrm.leads.where('res_contact_id').equals(`${state.focusContactId}`).toArray();
    // data = response;
    commit("setFocusContactEnquiries", data);
  },
  findContact: async ({ commit }, data) => {
    let result = {};
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    // console.log("DATA: ", data);
    // console.log("DATA.CONTACT: ", data.contact);
    // console.log("DATA.CONTACT.propd_contacts_pk_id: ", data.contact.propd_contacts_pk_id);
    try {
      let response = await dbcrm.contacts.where("pk_id").equals(data.contact.propd_contacts_pk_id).toArray();
      result = response;
      // console.log("response: ", response);
      // console.log("result: ", result);
      if (result.length == 0) {
        let res = await axios.post(`/api/v1/contacts/search/`, {
          contact: data.contact,
          user: data.user,
          id: data.contact.propd_contacts_pk_id,
        });
        if (res.data.data.length > 0) {
          let contact = res.data.data;
          await dbcrm.contacts
            .add({
              pk_id: contact.id,
              introduction_agent: contact.introduction_agent,
              created: contact.created,
              modified: contact.modified,
              source: contact.source,
              status: contact.status,
              legal_basis: contact.legal_basis,
              first_name: contact.first_name,
              last_name: contact.last_name,
              email: contact.email,
              cell_number: contact.cell_number,
              home_number: contact.home_number,
              work_number: contact.work_number,
              postal_address: contact.postal_address,
              physical_address: contact.physical_address,
              birthday: contact.birthday,
              contact_types: contact.contact_types,
              id_number: contact.id_number,
              branch: contact.branch,
              active: contact.active,
            })
            .catch((error) => {
              console.error("Adding leads error : ", error);
            });
        }
        result[0] = res.data.data;
        // console.log("res: ", res);
        // console.log("result[0]: ", result);
      }
      commit("setSearchContact", result);
    } catch (error) {
      console.error("Exception : ", error);
    }
  },
};
const mutations = {
  setContacts: (state, contacts) => (state.contacts = contacts),
  setFocusContact: (state, focusContact) =>
    (state.focusContactDetails = focusContact),
  setFocusContactId: (state, id) => (state.focusContactId = id),
  setFocusContactEnquiries: (state, data) =>
    (state.focusContactEnquiriesList = data),
  setSearchContact: (state, data) => (state.setSearchContact = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
