import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import vue3GoogleLogin from "vue3-google-login";
// import './registerServiceWorker'

const app = createApp(App);
app.use(router);
app.use(store);
app.use(vue3GoogleLogin, {
  clientId:
    "75785981338-9ps0u4lh1vkco9bj56q68ugqgs4m6bmi.apps.googleusercontent.com",
});
app.mount("#app");
