import axios from "axios";
import DBCRM from "../../assets/scripts/indexDB/config.js";

const state = {
  viewings: [],
  viewingsStatus: "pending",
  pending_vewings: 0,
  cancelled_vewings: 0,
  declined_vewings: 0,
  accepted_vewings: 0,
  work_time_slots: [],
  viewingComments: [],
  viewing_id: "",
};
const getters = {
  allViewings: (state) => state.viewings,
  currentViewingsFilter: (state) => state.viewingsStatus,
  pending_vewings: (state) => state.pending_vewings,
  cancelled_vewings: (state) => state.cancelled_vewings,
  declined_vewings: (state) => state.declined_vewings,
  accepted_vewings: (state) => state.accepted_vewings,
  allWorkTimeSlots: (state) => state.work_time_slots,
  getViewingComments: (state) => state.viewingComments,
  focusViewingId: (state) => state.viewing_id,
};
const actions = {
  syncViewings: async ({ commit }, ids) => {
    let user_id = ids.user;
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let response = await axios.get(`/api/v1/viewings/${user_id}`);

    for (let viewing of response.data.data) {
      let local = await dbcrm.viewings
        .get({
          id: viewing.pk_id,
        })
        .catch(function (err) {
          console.error("Exception: ", err.stack || err);
        });
      let comments = await axios.get(
        `/api/v1/comments/viewings/${viewing.pk_id}/`
      );
      if (local) {
        // dbcrm.viewings.where("id").equals(local.id).modify({
        //   id: viewing.id,
        //   accepted: viewing.accepted,
        //   agent: viewing.agent,
        //   agent_2_id: viewing.agent_2_id,
        //   agent_3_id: viewing.agent_3_id,
        //   agent_4_id: viewing.agent_4_id,
        //   agent_id: viewing.agent_id,
        //   cancelled: viewing.cancelled,
        //   customer: viewing.customer,
        //   customer_id: viewing.customer_id,
        //   date_accepted: viewing.date_accepted,
        //   date_declined: viewing.date_declined,
        //   date_initiated: viewing.date_initiated,
        //   declined: viewing.declined,
        //   guid: viewing.guid,
        //   listing_id: viewing.listing_id,
        //   listing_type: viewing.listing_type,
        //   request_count: viewing.request_count,
        //   requests: viewing.requests,
        // });

        dbcrm.viewings
          .where("id")
          .equals(local.id)
          .modify({
            id: viewing.pk_id,
            guid: viewing.guid,
            time: viewing.time,
            date: viewing.date,
            status: viewing.status,
            web_ref: viewing.web_ref,
            clients_fk_id: viewing.clients_fk_id,
            branches_fk_id: viewing.branches_fk_id,
            customer_email: viewing.customer_email,
            customer_id: viewing.customer_id,
            contact: viewing.contact,
            agent_available_times:
              JSON.parse(viewing.agent_available_times) || [],
            agent_proposed_times:
              JSON.parse(viewing.agent_proposed_times) || [],
            selected_times: JSON.parse(viewing.selected_times) || [],
            deleted: viewing.deleted,
            timestamp: viewing.timestamp,
          });

        for (let comment of comments.data.data) {
          let localcomment = await dbcrm.history.get({
            comments_pk_id: comment.comments_pk_id,
          });
          if (localcomment == undefined) {
            let newComment = await dbcrm.history
              .add({
                comments_pk_id: comment.comments_pk_id,
                leads_fk_id: comment.leads_fk_id,
                contacts_fk_id: comment.contacts_fk_id,
                viewing_fk_id: comment.viewing_fk_id,
                type: comment.type,
                title: comment.title,
                description: comment.description,
                author_email: comment.author_email,
                author_id: comment.author_id,
                created_date: comment.created_date,
                modified_date: comment.modified_date,
                deleted: comment.deleted,
                author: comment.author,
              })
              .catch((error) => console.error("Exception : ", error));
          }
          if (localcomment) {
            let newComment = await dbcrm.history
              .where("comments_pk_id")
              .equals(comment.comments_pk_id)
              .modify({
                comments_pk_id: comment.comments_pk_id,
                leads_fk_id: comment.leads_fk_id,
                contacts_fk_id: comment.contacts_fk_id,
                viewing_fk_id: comment.viewing_fk_id,
                type: comment.type,
                title: comment.title,
                description: comment.description,
                author_email: comment.author_email,
                author_id: comment.author_id,
                created_date: comment.created_date,
                modified_date: comment.modified_date,
                deleted: comment.deleted,
                author: comment.author,
              })
              .catch((error) => console.error("Exception : ", error));
          }
        }
      }
      if (local == undefined) {
        // dbcrm.viewings.add({
        //   id: viewing.id,
        //   accepted: viewing.accepted,
        //   agent: viewing.agent,
        //   agent_2_id: viewing.agent_2_id,
        //   agent_3_id: viewing.agent_3_id,
        //   agent_4_id: viewing.agent_4_id,
        //   agent_id: viewing.agent_id,
        //   cancelled: viewing.cancelled,
        //   customer: viewing.customer,
        //   customer_id: viewing.customer_id,
        //   date_accepted: viewing.date_accepted,
        //   date_declined: viewing.date_declined,
        //   date_initiated: viewing.date_initiated,
        //   declined: viewing.declined,
        //   guid: viewing.guid,
        //   listing_id: viewing.listing_id,
        //   listing_type: viewing.listing_type,
        //   request_count: viewing.request_count,
        //   requests: viewing.requests,
        // });
        dbcrm.viewings.add({
          id: viewing.pk_id,
          guid: viewing.guid,
          time: viewing.time,
          date: viewing.date,
          status: viewing.status,
          web_ref: viewing.web_ref,
          clients_fk_id: viewing.clients_fk_id,
          branches_fk_id: viewing.branches_fk_id,
          customer_email: viewing.customer_email,
          customer_id: viewing.customer_id,
          contact: viewing.contact,
          agent_available_times:
            JSON.parse(viewing.agent_available_times) || [],
          agent_proposed_times: JSON.parse(viewing.agent_proposed_times) || [],
          selected_times: JSON.parse(viewing.selected_times) || [],
          deleted: viewing.deleted,
          timestamp: viewing.timestamp,
        });

        for (let comment of comments.data.data) {
          let newComment = await dbcrm.history
            .add({
              comments_pk_id: comment.comments_pk_id,
              leads_fk_id: comment.leads_fk_id,
              contacts_fk_id: comment.contacts_fk_id,
              viewing_fk_id: comment.viewing_fk_id,
              type: comment.type,
              title: comment.title,
              description: comment.description,
              author_email: comment.author_email,
              author_id: comment.author_id,
              created_date: comment.created_date,
              modified_date: comment.modified_date,
              deleted: comment.deleted,
              author: comment.author,
            })
            .catch((error) => console.error("Exception : ", error));
        }
      }
    }
    // dbcrm.viewings
    //     .where("deleted")
    //     .equals("1")
    //     .delete()
    //     .then((res) => {
    //         console.log("Result : ", res);
    //     })
    //     .catch((error) => {
    //         console.error("Exception : ", error);
    //     });
    actions.filterViewings(
      {
        commit,
      },
      user_id
    );
  },
  fetchViewings: async ({ commit }, user_id) => {
    let data = [];
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let response = await dbcrm.viewings
      .where("clients_fk_id")
      .equals(`${user_id}`)
      .filter((view) => view.deleted == 0)
      .reverse()
      .sortBy("timestamp");
    // console.log("Fetching  responseresponse: ", response);

    data = response;
    commit("setViewings", data);
  },
  filterViewings: async ({ commit }, user_id) => {
    let data = [],
      response = [];

    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    // console.log("state.viewingsStatus : ", state.viewingsStatus);
    if (state.viewingsStatus == "pending") {
      response = await dbcrm.viewings
        .filter((view) => {
          // console.log("view.deleted: ", view);
          if (view.status == 0 && view.deleted == 0) return view;
        })
        .reverse()
        .sortBy("timestamp");
      // .toArray();
      // console.log("dbcrm.viewings : ", response);
    }
    if (state.viewingsStatus == "accepted") {
      response = await dbcrm.viewings
        .filter((view) => {
          // console.log("view.deleted: ", view);
          if (view.status == 1 && view.deleted == 0) return view;
        })
        .reverse()
        .sortBy("timestamp");
      // .toArray();
    }
    if (state.viewingsStatus == "declined") {
      response = await dbcrm.viewings
        .filter((view) => {
          // console.log("view.deleted: ", view);
          if (view.status == 2 && view.deleted == 0) return view;
        })
        .reverse()
        .sortBy("timestamp");
      // .toArray();
    }
    if (state.viewingsStatus == "cancelled") {
      response = await dbcrm.viewings
        .filter((view) => {
          // console.log("view.deleted: ", view);
          if (view.status == 3 && view.deleted == 0) return view;
        })
        .reverse()
        .sortBy("timestamp");
      // .toArray();
    }

    data = response;
    commit("setViewings", data);
  },
  setViewingsStatus: ({ commit }, filter) => {
    commit("setViewingsStatus", filter);
  },
  fetchViewingsCounts: async ({ commit }, user_id) => {
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    // let pending_vewings = await dbcrm.viewings
    //   .filter((view) => {
    //     if (view.accepted == 0 && view.declined == 0 && view.cancelled == 0)
    //       return view;
    //   })
    //   .toArray();
    let pending_vewings = await dbcrm.viewings
      .filter((view) => view.status == 0 && view.deleted == 0)
      .toArray();
    let cancelled_vewings = await dbcrm.viewings
      .filter((view) => view.status == 3 && view.deleted == 0)
      .toArray();

    // let cancelled_vewings = await dbcrm.viewings
    //   .filter((view) => {
    //     if (view.accepted == 0 && view.declined == 0 && view.cancelled == 1)
    //       return view;
    //   })
    //   .toArray();
    let declined_vewings = await dbcrm.viewings
      .filter((view) => view.status == 2 && view.deleted == 0)
      .toArray();
    // let declined_vewings = await dbcrm.viewings
    //   .filter((view) => {
    //     if (view.accepted == 0 && view.declined == 1 && view.cancelled == 0)
    //       return view;
    //   })
    //   .toArray();
    let accepted_vewings = await dbcrm.viewings
      .filter((view) => view.status == 1 && view.deleted == 0)
      .toArray();
    // let accepted_vewings = await dbcrm.viewings
    //   .filter((view) => {
    //     if (view.accepted == 1 && view.declined == 0 && view.cancelled == 0)
    //       return view;
    //   })
    //   .toArray();

    commit("setViewingsCounts", {
      pending_vewings: pending_vewings.length,
      cancelled_vewings: cancelled_vewings.length,
      declined_vewings: declined_vewings.length,
      accepted_vewings: accepted_vewings.length,
    });
  },
  fetchWorkTimeSlots: async ({ commit }, user) => {
    let response = await axios.get(
      `/api/v1/working-hours/${user.user_id}/${user.branch_id}`
    );
    if (response.data.code == 200) {
      commit("setWorkTimeSlots", response.data.slots);
    }
  },
  fetchViewingComments: async ({ commit }, viewing_id = null) => {
    let data = [];
    let response = [];
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    if (viewing_id) {
      response = await dbcrm.history
        .where("viewing_fk_id")
        .equals(viewing_id)
        .reverse()
        .sortBy("timestamp");
      data = response;
    }
    commit("setViewingComments", data);
  },
  setFocusViewingId: ({ commit }, id) => {
    commit("setFocusViewingId", id);
  },
};
const mutations = {
  setViewings: (state, viewings) => (state.viewings = viewings),
  setViewingsStatus: (state, status) => (state.viewingsStatus = status),
  setWorkTimeSlots: (state, work_time_slots) =>
    (state.work_time_slots = work_time_slots),
  setViewingsCounts: (state, viewingsCounts) => {
    state.pending_vewings = viewingsCounts.pending_vewings;
    state.cancelled_vewings = viewingsCounts.cancelled_vewings;
    state.declined_vewings = viewingsCounts.declined_vewings;
    state.accepted_vewings = viewingsCounts.accepted_vewings;
  },
  setViewingComments: (state, data) => (state.viewingComments = data),
  setFocusViewingId: (state, id) => (state.viewing_id = id),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
