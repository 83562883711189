<template>
  <div>
    <div
      id="menu-report-issue"
      class="menu menu-box-bottom menu-box-detached rounded-m"
    >
      <div class="menu-title row mb-0">
        <div class="col-10" style="display:flex!important;justify-content:center!important;padding:0;">
          <h3 style=" transform: translate(15%,55%);z-index: 0;position: absolute;">Report Issue</h3>
        </div>
        <div class="col-2">
          <span class="close-menu float-right mx-4 my-3 h6"
            ><i class="la la-times text-danger"></i
          ></span>
        </div>
        <p class="font-10 px-3 color-highlight mt-n2 mb-0 text-left">
          <!-- This will remove all data stored locally; sync will be required. -->
        </p>
      </div>

      <div class="divider divider-margins mb-2"></div>

      <div class="content mt-2">
        <div class="input-style input-style-2">
          <span class="input-style-1-active mx-5">Issue</span>
          <em><i class="la la-angle-down"></i></em>
          <select class="form-control task-items" v-model="reportType">
            <option value="Select Type" selected class="text-dark">
              Select Type
            </option>
            <option value="4" class="text-dark">Bug</option>
            <option value="5" class="text-dark">Business</option>
            <option value="2" class="text-dark">
              Product Update / Change Request
            </option>
          </select>
        </div>
        <div class="input-style input-style-2">
          <span class="input-style-1-active mx-5">Subject</span>
          <input
            type="name"
            class="form-control"
            id=""
            name="reportSubject"
            placeholder="Subject"
            v-model="reportSubject"
          />
        </div>

        <div class="input-style input-style-2">
          <span class="input-style-1-active mx-5">Message</span>
          <div class="col-md-10 col-sm-9 col-xs-12">
            <textarea
              name="reportMessage"
              class="form-control mb-2"
              placeholder="Please detail the issue. The support team will revert with a ticket reference."
              id="reportMessage"
              rows="5"
              v-model="reportMessage"
            ></textarea>
            <!-- <div class="row mb-0 text-left">
              <div class="col-md-6 col-sm-12 col-12 text-right mt-2">
                <i
                  class="la la-microphone-alt font-25 m-2"
                  id="start-record-issue-btn"
                ></i>
                <i
                  class="la la-pause-circle font-25 m-2"
                  id="pause-record-issue-btn"
                ></i>
                <i
                  class="la la-question-circle text-info"
                  style="font-size: 1.5rem !important"
                  data-toggle="modal"
                  data-target="#helpModalVoice"
                ></i>
              </div>
              <div class="col-12 col-md-12 col-sm-12 text-right">
                <p id="start-record-issue-btn-recording-instructions">
                  Press <strong> Microphone</strong> to enable voice access.
                </p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="content mt-2">
        <span
          class="btn btn-s my-4 rounded-1 btn-full col-12 bg-brand text-white text-uppercase font-900"
          @click="sendIssueReport"
          :class="spinner == 1 ? 'spinner' : ''"
          >{{ btnTitle }}</span
        >
        <!-- <span
          class="btn btn-s mt-2 mb-4 rounded-1 btn-full col-12 border text-brand text-uppercase font-900"
          @click="closeMenu"
          >Cancel</span
        > -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DBCRM from "@/assets/scripts/indexDB/config.js";
import axios from "axios";

export default {
  name: "ReportIssueModal",
  props: {},
  data() {
    return {
      spinner: 0,
      reportType: "Select Type",
      reportSubject: "",
      reportMessage: "",
      status: 1,
      priority: 1,
      category: 24,
      department: 15,
    };
  },
  computed: {
    ...mapGetters(["userData"]),
    btnTitle() {
      return this.spinner == 1 ? "Loading..." : "Report";
    },
  },
  methods: {
    async sendIssueReport() {
      this.spinner = 1;
      let dbcrm = DBCRM.connect();
      await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
      let active_branch = await dbcrm.branches.get({
        branches_pk_id: this.userData.active_branch,
      });

      let response = await axios.post(`/api/v1/reportIssue/`, {
        branch: active_branch.name,
        user: `${this.userData.first_name} ${this.userData.last_name}`,
        clients_pk_id: this.userData.clients_pk_id,
        email: this.userData.email,
        reportMessage: this.reportMessage,
        fname: this.userData.first_name,
        lname: this.userData.last_name,
        department: this.department,
        type: this.reportType,
        category: this.category,
        status: this.status,
        priority: this.priority,
        subject: this.reportSubject,
      });

      if (response.data.status == "Success") {
        this.$parent.toastTitle = response.data.status;
        this.$parent.toastDescription = "";
        this.showToast();
        this.spinner = 0;
        this.closeMenu();
      } else {
        this.$parent.toastTitle = response.data.status;
        this.$parent.toastDescription = "";
        this.showToast();
        this.spinner = 0;
        this.closeMenu();
      }
    },
    closeMenu() {
      let menus = document.querySelectorAll(".menu-active");
      document.getElementById("menu-hider").classList.remove("menu-active");
      Array.from(menus).forEach((el) => {
        el.classList.remove("menu-active");
      });
    },
    showToast() {
      this.$parent.showToast();
    },
  },
};
</script>
<style scoped>
.text-left {
  text-align: left;
}
.text-brand {
  color: var(--brand);
}
</style>
