import axios from "axios";
import DBCRM from "../../assets/scripts/indexDB/config.js";

const state = {
    dashboard_statistics_date: localStorage['dashboard_statistics_date'] || '7 Days'
};
const getters = {
    dashStatsDate: (state) => state.dashboard_statistics_date,
};
const actions = {
    getAgentStats: async ({
        commit
    }, data) => {
        // console.log("get AGENTSS DATA : ", data)
        let portalLeads = await axios.post(`/api/v1/portalLeadStats/`, {
            user: data,
            date: state.dashboard_statistics_date
        });
        return portalLeads.data;
    },
    setDate: async ({
        commit
    }, date) => {
        localStorage['dashboard_statistics_date'] = date;
        commit("setDashboardStatisticsDate", date);
    }

};
const mutations = {
    setDashboardStatisticsDate: (state, data) => (state.dashboard_statistics_date = data)
};

export default {
    state,
    getters,
    actions,
    mutations,
};