import axios from "axios";
import DBCRM from "../../assets/scripts/indexDB/config.js";

const state = {
  referrals: [],
  referralsStatus: "pending",
  internalBranches: [],
  internalAgents: [],
  pending_referrals: 0,
  accepted_referrals: 0,
  rejected_referrals: 0,
};
const getters = {
  allReferrals: (state) => state.referrals,
  allBranches: (state) => state.internalBranches,
  allAgents: (state) => state.internalAgents,
  currentReferralsFilter: (state) => state.referralsStatus,
  pending_referrals: (state) => state.pending_referrals,
  accepted_referrals: (state) => state.accepted_referrals,
  rejected_referrals: (state) => state.rejected_referrals,
};
const actions = {
  syncReferrals: async ({ commit }, ids) => {
    let user_id = ids.user;
    let branch_id = ids.branch;
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let response = await axios.get(
      `/api/v1/referrals/${user_id}/${branch_id}`
    );
    for (let referral of response.data.data) {
      let local = await dbcrm.referrals.get({
        id: referral.referrals_pk_id,
      });
      // console.log("Contact ID :  ", referral.contact_id);
      if (referral.contact) {
        // let contact = await dbcrm.contacts.get({
        //   pk_id: referral.contact_id,
        // });
        if (local == undefined) {
          dbcrm.referrals
            .add({
              id: referral.referrals_pk_id,
              contact_id: referral.contact_id,
              type: referral.type,
              status: referral.status,
              start_date: referral.start_date,
              end_date: referral.end_date,
              referred_by_agent: referral.referred_by_agent,
              referred_by_branch: referral.referred_by_branch,
              referred_to_agent: referral.referred_to_agent,
              referred_to_branch: referral.referred_to_branch,
              percentage_fee: referral.percentage_fee,
              value_fee: referral.value_fee,
              guid: referral.guid,
              deleted: referral.deleted,
              referral: {
                first_name: referral.first_name,
                last_name: referral.last_name,
                email: referral.email,
                cell_number: referral.cell_number,
                agency: referral.agency,
                branches_pk_id: referral.branches_pk_id,
                clients_pk_id: referral.clients_pk_id,
                entity_type: referral.entity_type,
              },
              contact: referral.contact,
            })
            .catch((error) => {
              console.error("Adding leads error : ", error);
            });
        }
        if (local) {
          dbcrm.referrals
            .where("pk_id")
            .equals(local.pk_id)
            .modify({
              id: referral.referrals_pk_id,
              contact_id: referral.contact_id,
              type: referral.type,
              status: referral.status,
              start_date: referral.start_date,
              end_date: referral.end_date,
              referred_by_agent: referral.referred_by_agent,
              referred_by_branch: referral.referred_by_branch,
              referred_to_agent: referral.referred_to_agent,
              referred_to_branch: referral.referred_to_branch,
              percentage_fee: referral.percentage_fee,
              value_fee: referral.value_fee,
              guid: referral.guid,
              deleted: referral.deleted,
              referral: {
                first_name: referral.first_name,
                last_name: referral.last_name,
                email: referral.email,
                cell_number: referral.cell_number,
                agency: referral.agency,
                branches_pk_id: referral.branches_pk_id,
                clients_pk_id: referral.clients_pk_id,
                entity_type: referral.entity_type,
              },
              contact: referral.contact,
            });
        }
      }
    }
    dbcrm.referrals
      .where("deleted")
      .equals("1")
      .delete()
      .then((res) => {
        // console.log("Result : ", res);
      })
      .catch((error) => {
        console.error("Exception : ", error);
      });
    actions.filterReferrals(
      {
        commit,
      },
      user_id
    );
  },
  fetchReferrals: async ({ commit }, user) => {
    let data = [];
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let response = await dbcrm.referrals
      .filter((referral) =>
        [referral.referred_by_agent, referral.referred_to_agent].includes(
          user.clients_pk_id
        )
      )
      .filter((referral) =>
        [referral.referred_by_branch, referral.referred_to_branch].includes(
          user.active_branch
        )
      )
      .toArray();
    data = response;
    commit("setReferrals", data);
  },
  filterReferrals: async ({ commit }, user_id) => {
    let data = [],
      response = [];

    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    if (state.referralsStatus == "pending") {
      response = await dbcrm.referrals
        .filter((referral) => referral.status == "pending")
        .toArray();
    }
    if (state.referralsStatus == "accepted") {
      response = await dbcrm.referrals
        .filter((referral) => referral.status == "accepted")
        .toArray();
    }
    if (state.referralsStatus == "rejected") {
      response = await dbcrm.referrals
        .filter((referral) => referral.status == "rejected")
        .toArray();
    }
    data = response;
    commit("setReferrals", data);
  },
  setReferralsStatus: ({ commit }, filter) => {
    commit("setReferralsStatus", filter);
  },
  fetchReferralCounts: async ({ commit }, user_id) => {
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let pending_referrals = await dbcrm.referrals
      .filter((referral) => referral.status == "pending")
      .toArray();
    let accepted_referrals = await dbcrm.referrals
      .filter((referral) => referral.status == "accepted")
      .toArray();
    let rejected_referrals = await dbcrm.referrals
      .filter((referral) => referral.status == "rejected")
      .toArray();
    commit("setReferralCounts", {
      pending_referrals: pending_referrals.length,
      accepted_referrals: accepted_referrals.length,
      rejected_referrals: rejected_referrals.length,
    });
  },
  getBranches: async ({ commit }) => {
    let branches = [];
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    branches = await axios.get("/api/v1/branches/");
    commit("setInternalBranches", branches.data.data);
  },
  getAgents: async ({ commit }, branch) => {
    let agents = [];
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    agents = await axios.post(
      `/api/v1/agents/${branch}/`
    );
    commit("setInternalAgents", agents.data.data);
  },
};
const mutations = {
  setReferrals: (state, referrals) => (state.referrals = referrals),
  setReferralsStatus: (state, status) => (state.referralsStatus = status),
  setReferralCounts: (state, referralCounts) => {
    state.pending_referrals = referralCounts.pending_referrals;
    state.accepted_referrals = referralCounts.accepted_referrals;
    state.rejected_referrals = referralCounts.rejected_referrals;
  },
  setInternalBranches: (state, branches) => (state.internalBranches = branches),
  setInternalAgents: (state, agents) => (state.internalAgents = agents),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
