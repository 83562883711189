<template>
  <router-view />
  <AndroidInstallPrompt />
  <EfficiencyDetailsModal />
  <ResetDBModal />
  <ReportIssueModal />
  <NotificationToast :title="toastTitle" :description="toastDescription" />
  <NetworkConnectionNotification :connection="networkConnection" />
</template>
<script>
import session from "@/assets/scripts/session.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import DBCRM from "@/assets/scripts/indexDB/config.js";
import AndroidInstallPrompt from "@/components/AndroidInstallPrompt.vue";
import IosInstallPropmpt from "@/components/IosInstallPrompt.vue";
import EfficiencyDetailsModal from "@/components/EfficiencyDetailsModal.vue";
import ResetDBModal from "@/components/ResetDBModal.vue";
import ReportIssueModal from "@/components/ReportIssueModal.vue";
import NotificationToast from "@/components/NotificationToast.vue";
import NetworkConnectionNotification from "@/components/NetworkConnectionNotification.vue";

import Cookies from "js-cookie";
import axios from "axios";

export default {
  components: {
    AndroidInstallPrompt,
    IosInstallPropmpt,
    EfficiencyDetailsModal,
    ResetDBModal,
    ReportIssueModal,
    NotificationToast,
    NetworkConnectionNotification,
  },
  data() {
    return {
      store: [],
      user_id: null,
      toastTitle: "",
      toastDescription: "",
      networkConnection: 1,
    };
  },
  methods: {
    ...mapActions([
      "setBranches",
      "setUserData",
      "fetchNotifications",
      "syncNotifications",
      "addComment",
      "updateLeadStatus",
    ]),
    async updateLocalData(data) {
      let dbcrm = DBCRM.connect();
      await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
      let user = await dbcrm.user.get({
        clients_pk_id: data.user.clients_pk_id,
      });
      if (user == undefined) {
        await dbcrm.user.clear();
        await dbcrm.user.add({
          clients_pk_id: data.user.clients_pk_id,
          client_id: data.user.id,
          first_name: data.user.first_name,
          last_name: data.user.last_name,
          profile_pic: data.user.profile_pic,
          active_branch: data.user.active_branches_pk_id,
          default_branch: data.user.default_branches_pk_id,
          cell_number: data.user.cell_number,
          email: data.user.email,
          super_global_admin_user: data.user.super_global_admin_user,
        });
      }
      if (user) {
        await dbcrm.user
          .where("clients_pk_id")
          .equals(data.user.clients_pk_id)
          .modify({
            clients_pk_id: data.user.clients_pk_id,
            client_id: data.user.id,
            first_name: data.user.first_name,
            last_name: data.user.last_name,
            profile_pic: data.user.profile_pic,
            active_branch: data.user.active_branches_pk_id,
            default_branch: data.user.default_branches_pk_id,
            cell_number: data.user.cell_number,
            email: data.user.email,
            super_global_admin_user: data.user.super_global_admin_user,
          });
      }
      for (let branch of data.branches) {
        // console.log("branch : ", branch);
        let item = await dbcrm.branches.get({
          branches_pk_id: branch.branches_pk_id,
        });
        if (item == undefined) {
          await dbcrm.branches.add({
            branches_pk_id: branch.branches_pk_id,
            name: branch.name,
            propd_id: branch.id,
          });
        }
      }
    },
    async getUserData() {
      let id = 0;
      if (this.userData.clients_pk_id) id = this.userData.clients_pk_id;
      if (!this.userData.clients_pk_id) id = Cookies.get("user");
      // if (!id) id = 2976;
      return await session.getUserData(id);
    },
    showToast(id = "notification-1") {
      let notificationToast = document.getElementById(id);
      notificationToast = new bootstrap.Toast(notificationToast);
      notificationToast.show();
    },
    async updateOnlineStatus() {
      if (navigator.onLine) this.networkConnection = 1;
      if (!navigator.onLine) this.networkConnection = 0;
      this.showToast("network-connection");
      if (navigator.onLine) {
        let dbcrm = DBCRM.connect();
        await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
        let messages = await dbcrm.message_queue
          .where("author_id")
          .equals(this.userData.clients_pk_id)
          .toArray();

        for (let message of messages) {
          if (message.action_type == "Note") {
            this.addComment(message.data).then(async () => {
              if (message.data.leads_fk_id) {
                this.updateLeadStatus({
                  leads_pk_id: message.data.leads_fk_id,
                  stage: "active",
                });
              }
              await dbcrm.message_queue.delete(message.pk_id);
            });
          }
          if (message.action_type == "Task") {
            let response = await axios
              .post(
                `/api/v1/tasks/create/${this.userData.clients_pk_id}`,
                message.data.taskData
              )
              .catch((error) => {
                console.error("Exception : ", error);
              });
            if (response.data.message) {
              await this.addComment(message.data.commentData);
              if (message.data.commentData.leads_fk_id) {
                this.updateLeadStatus({
                  leads_pk_id: message.data.commentData.leads_fk_id,
                  stage: "active",
                });
              }
            }
            await dbcrm.message_queue.delete(message.pk_id);
          }

          // if (message.action_type == "Contact") {
          //   if (message.data.new == 0) {
          //     Controllers.updateContact(message.data).then(async (response) => {
          //       Controllers.showToast("notification-1", response.message);
          //       await dbcrm.message_queue.delete(message.pk_id);
          //     });
          //     return;
          //   }
          //   Controllers.createNewContact(message.data).then(
          //     async (response) => {
          //       Controllers.showToast("notification-1", response.message);
          //       await dbcrm.message_queue.delete(message.pk_id);
          //     }
          //   );
          // }
          // if (message.action_type == "Lead") {
          //   if (message.data.new == 0) {
          //     Controllers.updateLead(message.data).then(async (response) => {
          //       Controllers.showToast(
          //         "notification-1",
          //         response.message,
          //         response.description
          //       );
          //       await dbcrm.message_queue.delete(message.pk_id);
          //     });
          //     return;
          //   }
          //   Controllers.createNewLead(message.data).then(async (response) => {
          //     Controllers.showToast(
          //       "notification-1",
          //       response.message,
          //       response.description
          //     );
          //     await dbcrm.message_queue.delete(message.pk_id);
          //   });
          // }
          // if (message.action_type == "Referral") {
          //   Controllers.sendNewReferral(message.data).then(async (response) => {
          //     Controllers.showToast(
          //       "notification-1",
          //       response.message,
          //       response.description
          //     );
          //     await dbcrm.message_queue.delete(message.pk_id);
          //   });
          // }
          // if (message.action_type == "t_app") {
          //   Controllers.sendTenantApplication(message.data).then(
          //     async (response) => {
          //       Controllers.showToast(
          //         "notification-1",
          //         response.message,
          //         response.description
          //       );
          //       await dbcrm.message_queue.delete(message.pk_id);
          //     }
          //   );
          // }
          // if (message.action_type == "complete_task") {
          //   Controllers.completeTask(message.data).then(async (response) => {
          //     Controllers.showToast(
          //       "notification-1",
          //       response.message,
          //       response.description
          //     );
          //     await dbcrm.message_queue.delete(message.pk_id);
          //   });
          // }
          // if (message.action_type == "viewing-action") {
          //   Controllers.viewingAction(message.data).then(async (response) => {
          //     Controllers.showToast(
          //       "notification-1",
          //       response.message,
          //       response.description
          //     );
          //     await dbcrm.message_queue.delete(message.pk_id);
          //   });
          // }
          // if (message.action_type == "reschedule-viewing") {
          //   Controllers.rescheduleViewing(message.data).then(
          //     async (response) => {
          //       Controllers.showToast(
          //         "notification-1",
          //         response.message,
          //         response.description
          //       );
          //       await dbcrm.message_queue.delete(message.pk_id);
          //     }
          //   );
          // }
          // if (message.action_type == "update-lead") {
          //   Controllers.updateLead(message.data, message.data.id).then(
          //     async (response) => {
          //       Controllers.showToast(
          //         "notification-1",
          //         response.message,
          //         response.description
          //       );
          //       await dbcrm.message_queue.delete(message.pk_id);
          //     }
          //   );
          // }
        }
      }
    },
  },
  computed: {
    ...mapGetters(["branches", "userData"]),
  },
  async mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    if (localStorage["clients_pk_id"]) {
      let user = localStorage["clients_pk_id"];
      Cookies.set("user", user);
    }
    await this.updateLocalData(await this.getUserData());
    this.setUserData();
    this.setBranches();
  },
};
</script>
<style lang="css">
:root {
  --brand: #093c71;
  --brand-secondary: #f1c40f;
  --tenant: #0090bf;
  --seller: #d0112b;
  --buyer: #fc92a4;
  --new-notification: #b4daff;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.text-left {
  text-align: left;
}
.menu {
  display: block !important;
}

nav {
  padding: 30px;
}
.bg-brand {
  background: var(--brand);
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
