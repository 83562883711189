import axios from "axios";
import DBCRM from "../../assets/scripts/indexDB/config.js";

const state = {
  leads: [],
  stage: "all",
  listing_type: "rental",
  comments: [],
  focusLead: null,
  branch: localStorage["active_branch"],
  branches: [],
  user: localStorage["clients_pk_id"],
  clients_id: localStorage["clients_id"],
  pending_leads: 0,
  active_leads: 0,
  ignored_leads: 0,
  userData: [],
  leadsCTAS: [],
  matchResults: [],
  lead_search_string: null,
};
const getters = {
  allLeads: (state) => state.leads,
  allComments: (state) => state.comments,
  stage: (state) => state.stage,
  listingType: (state) => state.listing_type,
  focusLead: (state) => state.focusLead,
  user: (state) => state.user,
  userData: (state) => state.userData,
  clients_id: (state) => state.clients_id,
  branch: (state) => state.branch,
  branches: (state) => state.branches,
  pending_leads: (state) => state.pending_leads,
  active_leads: (state) => state.active_leads,
  ignored_leads: (state) => state.ignored_leads,
  matchResults: (state) => state.matchResults,
};
const actions = {
  updateLeadStatus: async ({ commit }, data) => {
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    console.log("data update status : ", data);

    if (data.pdms_stage) {
      await dbcrm.leads
        .where("leads_pk_id")
        .equals(parseInt(data.leads_pk_id))
        .modify({
          stage: data.stage,
          pdms_stage: data.pdms_stage
        });
    } else {
      await dbcrm.leads
        .where("leads_pk_id")
        .equals(parseInt(data.leads_pk_id))
        .modify({
          stage: data.stage,
        });
    }
    await axios.post(`/api/v1/lead/update/`, {
      field: "stage",
      pdms_stage: data.pdms_stage || null,
      value: data.stage,
      lead_id: data.leads_pk_id,
    });
    if (["won", "lost", "dormant"].includes(data.stage)) {
      actions.fetchLeads({
        commit,
      });
      actions.fetchLeadCounts({
        commit,
      });
    }
  },
  syncLeadsData: async ({ commit }, ids) => {
    let branch_id = ids.branch; //43;
    let user_id = ids.user; //2976;
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    if (window.navigator.onLine) {
      let connection_type = navigator.connection
        ? navigator.connection.type
        : null;
      if (
        connection_type == null ||
        connection_type == "wifi" ||
        localStorage["mobileDataSync"] == 1
      ) {
        let response = await axios.get(
          `/api/v1/leads/user/${ids.user_propd_id}/branch/${branch_id}/`
        );
        for (let lead of response.data.data) {
          let local = await dbcrm.leads.get({
            leads_pk_id: lead.leads_pk_id,
          });
          let ctas = await axios.get(`/api/v1/leads/${lead.leads_pk_id}/ctas/`);
          let comments = await axios.get(
            `/api/v1/comments/leads/${lead.leads_pk_id}/`
          );
          // data = response.data.data;

          if (local == undefined) {
            dbcrm.leads
              .add({
                leads_pk_id: lead.leads_pk_id,
                assigned_by_id: lead.assigned_by_id,
                branches_fk_id: lead.branches_fk_id,
                category_id: lead.category_id,
                created_by_id: lead.created_by_id,
                timestamp: new Date(),
                date_create: lead.date_create,
                date_modify: lead.date_modify,
                modify_by_id: lead.modify_by_id,
                begin_date: lead.begin_date,
                contact_id: lead.contact_id,
                opportunity: lead.opportunity,
                stage_id: lead.stage_id,
                stage: lead.stage,
                pdms_stage: lead.pdms_stage,
                property_type: lead.property_type,
                listing_type: lead.listing_type,
                bedrooms: lead.bedrooms,
                bathrooms: lead.bathrooms,
                lead_type: lead.lead_type,
                suburb: lead.suburb,
                web_ref: lead.web_ref,
                source: lead.source,
                contact_type: lead.contact_type,
                contact_comments: lead.contact_comments,
                disclosure: lead.disclosure,
                description: lead.discription,
                ignored: lead.ignored,
                deleted: lead.deleted,
                propd_leads_pk_id: lead.propd_leads_pk_id,
                id: lead.id,
                source_ref: lead.source_ref,
                branch: lead.branch,
                agent: lead.agent,
                active: lead.active,
                source_description: lead.source_description,
                residential: lead.residential,
                holiday: lead.holiday,
                commercial: lead.commercial,
                development: lead.development,
                message: lead.message,
                contact: lead.contact,
                profile: lead.profile,
                client_id: lead.client_id,
                property_id: lead.property,
                price_from: lead.price_from,
                price_to: lead.price_to,
                meta: lead.meta,
                team: lead.team,
                telephonic: lead.telephonic,
                lead_status: lead.lead_status,
                bitrix_id: lead.bitrix_id,
                lead_email: lead.lead_email,
                propd_contacts_pk_id: lead.propd_contacts_pk_id,
                mailerlite_pushed: lead.mailerlite_pushed,
                res_jp_ref: lead.res_jp_ref,
                res_web_ref: lead.res_web_ref,
                res_listing_type: lead.res_listing_type,
                res_property_type: lead.res_property_type,
                res_branch: lead.res_branch,
                res_price: lead.res_price,
                res_bathrooms: lead.res_bathrooms,
                res_bedrooms: lead.res_bedrooms,
                res_suburb: lead.res_suburb,
                res_suburb_search: lead.res_suburb_search,
                res_agent: lead.res_agent || "",
                res_agent_full_name: lead.res_agent_full_name,
                res_agent_2: lead.res_agent_2 || "",
                res_agent_full_name2: lead.res_agent_full_name2,
                res_agent_3: lead.res_agent_3 || "",
                res_agent_full_name3: lead.res_agent_full_name3,
                res_agent_4: lead.res_agent_4 || "",
                res_agent_full_name4: lead.res_agent_full_name4,
                contact_title: lead.contact_title,
                contact_first_name: lead.contact_first_name,
                contact_last_name: lead.contact_last_name,
                contact_cell_number: lead.contact_cell_number,
                contact_email: lead.contact_email,
                res_contact_id: lead.contact_email,
              })
              .catch((error) => {
                console.error("Adding leads error : ", error);
              });

            for (let cta of ctas.data.data) {
              let newCTA = await dbcrm.crm_lead_ctas
                .add({
                  pk_id: cta.pk_id,
                  clients_pk_id: cta.clients_pk_id,
                  branches_pk_id: cta.branches_pk_id,
                  leads_pk_id: cta.leads_pk_id,
                  data: cta.data,
                  cta: cta.cta,
                  created_date: cta.timestamp,
                  timestamp: new Date(),
                })
                .catch((error) => console.error("Exception : ", error));
            }

            for (let comment of comments.data.data) {
              let newComment = await dbcrm.history
                .add({
                  comments_pk_id: comment.comments_pk_id,
                  leads_fk_id: comment.leads_fk_id,
                  contacts_fk_id: comment.contacts_fk_id,
                  viewing_fk_id: comment.viewing_fk_id,
                  type: comment.type,
                  title: comment.title,
                  description: comment.description,
                  author_email: comment.author_email,
                  author_id: comment.author_id,
                  created_date: comment.created_date,
                  modified_date: comment.modified_date,
                  deleted: comment.deleted,
                  author: comment.author,
                })
                .catch((error) => console.error("Exception : ", error));
            }
          }
          if (local) {
            dbcrm.leads
              .where("leads_pk_id")
              .equals(lead.leads_pk_id)
              .modify({
                leads_pk_id: lead.leads_pk_id,
                assigned_by_id: lead.assigned_by_id,
                branches_fk_id: lead.branches_fk_id,
                category_id: lead.category_id,
                created_by_id: lead.created_by_id,
                timestamp: new Date(),
                date_create: lead.date_create,
                date_modify: lead.date_modify,
                modify_by_id: lead.modify_by_id,
                begin_date: lead.begin_date,
                contact_id: lead.contact_id,
                opportunity: lead.opportunity,
                stage_id: lead.stage_id,
                stage: lead.stage,
                pdms_stage: lead.pdms_stage,
                property_type: lead.property_type,
                listing_type: lead.listing_type,
                bedrooms: lead.bedrooms,
                bathrooms: lead.bathrooms,
                lead_type: lead.lead_type,
                suburb: lead.suburb,
                web_ref: lead.web_ref,
                source: lead.source,
                contact_type: lead.contact_type,
                contact_comments: lead.contact_comments,
                disclosure: lead.disclosure,
                description: lead.discription,
                ignored: lead.ignored,
                deleted: lead.deleted,
                propd_leads_pk_id: lead.propd_leads_pk_id,
                id: lead.id,
                source_ref: lead.source_ref,
                branch: lead.branch,
                agent: lead.agent,
                active: lead.active,
                source_description: lead.source_description,
                residential: lead.residential,
                holiday: lead.holiday,
                commercial: lead.commercial,
                development: lead.development,
                message: lead.message,
                contact: lead.contact,
                profile: lead.profile,
                client_id: lead.client_id,
                property_id: lead.property,
                price_from: lead.price_from,
                price_to: lead.price_to,
                meta: lead.meta,
                team: lead.team,
                telephonic: lead.telephonic,
                lead_status: lead.lead_status,
                bitrix_id: lead.bitrix_id,
                lead_email: lead.lead_email,
                propd_contacts_pk_id: lead.propd_contacts_pk_id,
                mailerlite_pushed: lead.mailerlite_pushed,
                res_jp_ref: lead.res_jp_ref,
                res_web_ref: lead.res_web_ref,
                res_listing_type: lead.res_listing_type,
                res_property_type: lead.res_property_type,
                res_branch: lead.res_branch,
                res_price: lead.res_price,
                res_bathrooms: lead.res_bathrooms,
                res_bedrooms: lead.res_bedrooms,
                res_suburb: lead.res_suburb,
                res_suburb_search: lead.res_suburb_search,
                res_agent: lead.res_agent || "",
                res_agent_full_name: lead.res_agent_full_name,
                res_agent_2: lead.res_agent_2 || "",
                res_agent_full_name2: lead.res_agent_full_name2,
                res_agent_3: lead.res_agent_3 || "",
                res_agent_full_name3: lead.res_agent_full_name3,
                res_agent_4: lead.res_agent_4 || "",
                res_agent_full_name4: lead.res_agent_full_name4,
                contact_title: lead.contact_title,
                contact_first_name: lead.contact_first_name,
                contact_last_name: lead.contact_last_name,
                contact_cell_number: lead.contact_cell_number,
                contact_email: lead.contact_email,
                res_contact_id: lead.contact_email,
              });

            for (let cta of ctas.data.data) {
              let localcta = await dbcrm.crm_lead_ctas.get({
                pk_id: cta.pk_id,
              });
              if (localcta == undefined) {
                let newCTA = await dbcrm.crm_lead_ctas
                  .add({
                    pk_id: cta.pk_id,
                    clients_pk_id: cta.clients_pk_id,
                    branches_pk_id: cta.branches_pk_id,
                    leads_pk_id: cta.leads_pk_id,
                    data: cta.data,
                    cta: cta.cta,
                    created_date: cta.timestamp,
                    timestamp: new Date(),
                  })
                  .catch((error) => console.error("Exception : ", error));
              }

              if (localcta) {
                let update_cta = await dbcrm.crm_lead_ctas
                  .where("pk_id")
                  .equals(cta.pk_id)
                  .modify({
                    pk_id: cta.pk_id,
                    clients_pk_id: cta.clients_pk_id,
                    branches_pk_id: cta.branches_pk_id,
                    leads_pk_id: cta.leads_pk_id,
                    data: cta.data,
                    cta: cta.cta,
                    created_date: cta.timestamp,
                    timestamp: new Date(),
                  })
                  .catch((error) => console.error("Exception : ", error));
              }
            }

            for (let comment of comments.data.data) {
              let localcomment = await dbcrm.history.get({
                comments_pk_id: comment.comments_pk_id,
              });
              if (!localcomment || localcomment == undefined) {
                let newComment = await dbcrm.history
                  .add({
                    comments_pk_id: comment.comments_pk_id,
                    leads_fk_id: comment.leads_fk_id,
                    contacts_fk_id: comment.contacts_fk_id,
                    viewing_fk_id: comment.viewing_fk_id,
                    type: comment.type,
                    title: comment.title,
                    description: comment.description,
                    author_email: comment.author_email,
                    author_id: comment.author_id,
                    created_date: comment.created_date,
                    modified_date: comment.modified_date,
                    deleted: comment.deleted,
                    author: comment.author,
                    history_type: comment.history_type,
                  })
                  .catch((error) => console.error("Exception : ", error));
              }
              if (localcomment) {
                let newComment = await dbcrm.history
                  .where("comments_pk_id")
                  .equals(comment.comments_pk_id)
                  .modify({
                    comments_pk_id: comment.comments_pk_id,
                    leads_fk_id: comment.leads_fk_id,
                    contacts_fk_id: comment.contacts_fk_id,
                    viewing_fk_id: comment.viewing_fk_id,
                    type: comment.type,
                    title: comment.title,
                    description: comment.description,
                    author_email: comment.author_email,
                    author_id: comment.author_id,
                    created_date: comment.created_date,
                    modified_date: comment.modified_date,
                    deleted: comment.deleted,
                    author: comment.author,
                    history_type: comment.history_type,
                  })
                  .catch((error) => console.error("Exception : ", error));
              }
            }
          }
        }
      }
    }

    dbcrm.leads
      .where("deleted")
      .equals("1")
      .or("stage")
      .equals("lost")
      .or("stage")
      .equals("won")
      .delete()
      .then((res) => { })
      .catch((error) => {
        console.error("Exception : ", error);
      });
    actions.fetchLeads({
      commit,
    });
  },
  backgroundSyncLeadsData: async ({ commit }, ids) => {
    let branch_id = ids.branch; //43;
    let user_id = ids.user; //2976;
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let response = await axios.get(
      `/api/v1/leads/user/${ids.user_propd_id}/branch/${branch_id}/lead/${ids.lead_id}/`
    );
    for (let lead of response.data.data) {
      let local = await dbcrm.leads.get({
        leads_pk_id: lead.leads_pk_id,
      });
      let ctas = await axios.get(`/api/v1/leads/${lead.leads_pk_id}/ctas/`);
      let comments = await axios.get(
        `/api/v1/comments/leads/${lead.leads_pk_id}/`
      );
      // data = response.data.data;

      if (local == undefined) {
        dbcrm.leads
          .add({
            leads_pk_id: lead.leads_pk_id,
            assigned_by_id: lead.assigned_by_id,
            branches_fk_id: lead.branches_fk_id,
            category_id: lead.category_id,
            created_by_id: lead.created_by_id,
            timestamp: new Date(),
            date_create: lead.date_create,
            date_modify: lead.date_modify,
            modify_by_id: lead.modify_by_id,
            begin_date: lead.begin_date,
            contact_id: lead.contact_id,
            opportunity: lead.opportunity,
            stage_id: lead.stage_id,
            stage: lead.stage,
            pdms_stage: lead.pdms_stage,
            property_type: lead.property_type,
            listing_type: lead.listing_type,
            bedrooms: lead.bedrooms,
            bathrooms: lead.bathrooms,
            lead_type: lead.lead_type,
            suburb: lead.suburb,
            web_ref: lead.web_ref,
            source: lead.source,
            contact_type: lead.contact_type,
            contact_comments: lead.contact_comments,
            disclosure: lead.disclosure,
            description: lead.discription,
            ignored: lead.ignored,
            deleted: lead.deleted,
            propd_leads_pk_id: lead.propd_leads_pk_id,
            id: lead.id,
            source_ref: lead.source_ref,
            branch: lead.branch,
            agent: lead.agent,
            active: lead.active,
            source_description: lead.source_description,
            residential: lead.residential,
            holiday: lead.holiday,
            commercial: lead.commercial,
            development: lead.development,
            message: lead.message,
            contact: lead.contact,
            profile: lead.profile,
            client_id: lead.client_id,
            property_id: lead.property,
            price_from: lead.price_from,
            price_to: lead.price_to,
            meta: lead.meta,
            team: lead.team,
            telephonic: lead.telephonic,
            lead_status: lead.lead_status,
            bitrix_id: lead.bitrix_id,
            lead_email: lead.lead_email,
            propd_contacts_pk_id: lead.propd_contacts_pk_id,
            mailerlite_pushed: lead.mailerlite_pushed,
            res_jp_ref: lead.res_jp_ref,
            res_web_ref: lead.res_web_ref,
            res_listing_type: lead.res_listing_type,
            res_property_type: lead.res_property_type,
            res_branch: lead.res_branch,
            res_price: lead.res_price,
            res_bathrooms: lead.res_bathrooms,
            res_bedrooms: lead.res_bedrooms,
            res_suburb: lead.res_suburb,
            res_suburb_search: lead.res_suburb_search,
            res_agent: lead.res_agent || "",
            res_agent_full_name: lead.res_agent_full_name,
            res_agent_2: lead.res_agent_2 || "",
            res_agent_full_name2: lead.res_agent_full_name2,
            res_agent_3: lead.res_agent_3 || "",
            res_agent_full_name3: lead.res_agent_full_name3,
            res_agent_4: lead.res_agent_4 || "",
            res_agent_full_name4: lead.res_agent_full_name4,
            contact_title: lead.contact_title,
            contact_first_name: lead.contact_first_name,
            contact_last_name: lead.contact_last_name,
            contact_cell_number: lead.contact_cell_number,
            contact_email: lead.contact_email,
            res_contact_id: lead.contact_email,
          })
          .catch((error) => {
            console.error("Adding leads error : ", error);
          });

        for (let cta of ctas.data.data) {
          let newCTA = await dbcrm.crm_lead_ctas
            .add({
              pk_id: cta.pk_id,
              clients_pk_id: cta.clients_pk_id,
              branches_pk_id: cta.branches_pk_id,
              leads_pk_id: cta.leads_pk_id,
              data: cta.data,
              cta: cta.cta,
              created_date: cta.timestamp,
              timestamp: new Date(),
            })
            .catch((error) => console.error("Exception : ", error));
        }

        for (let comment of comments.data.data) {
          let newComment = await dbcrm.history
            .add({
              comments_pk_id: comment.comments_pk_id,
              leads_fk_id: comment.leads_fk_id,
              contacts_fk_id: comment.contacts_fk_id,
              viewing_fk_id: comment.viewing_fk_id,
              type: comment.type,
              title: comment.title,
              description: comment.description,
              author_email: comment.author_email,
              author_id: comment.author_id,
              created_date: comment.created_date,
              modified_date: comment.modified_date,
              deleted: comment.deleted,
              author: comment.author,
            })
            .catch((error) => console.error("Exception : ", error));
        }
      }
      if (local) {
        dbcrm.leads
          .where("leads_pk_id")
          .equals(lead.leads_pk_id)
          .modify({
            leads_pk_id: lead.leads_pk_id,
            assigned_by_id: lead.assigned_by_id,
            branches_fk_id: lead.branches_fk_id,
            category_id: lead.category_id,
            created_by_id: lead.created_by_id,
            timestamp: new Date(),
            date_create: lead.date_create,
            date_modify: lead.date_modify,
            modify_by_id: lead.modify_by_id,
            begin_date: lead.begin_date,
            contact_id: lead.contact_id,
            opportunity: lead.opportunity,
            stage_id: lead.stage_id,
            stage: lead.stage,
            pdms_stage: lead.pdms_stage,
            property_type: lead.property_type,
            listing_type: lead.listing_type,
            bedrooms: lead.bedrooms,
            bathrooms: lead.bathrooms,
            lead_type: lead.lead_type,
            suburb: lead.suburb,
            web_ref: lead.web_ref,
            source: lead.source,
            contact_type: lead.contact_type,
            contact_comments: lead.contact_comments,
            disclosure: lead.disclosure,
            description: lead.discription,
            ignored: lead.ignored,
            deleted: lead.deleted,
            propd_leads_pk_id: lead.propd_leads_pk_id,
            id: lead.id,
            source_ref: lead.source_ref,
            branch: lead.branch,
            agent: lead.agent,
            active: lead.active,
            source_description: lead.source_description,
            residential: lead.residential,
            holiday: lead.holiday,
            commercial: lead.commercial,
            development: lead.development,
            message: lead.message,
            contact: lead.contact,
            profile: lead.profile,
            client_id: lead.client_id,
            property_id: lead.property,
            price_from: lead.price_from,
            price_to: lead.price_to,
            meta: lead.meta,
            team: lead.team,
            telephonic: lead.telephonic,
            lead_status: lead.lead_status,
            bitrix_id: lead.bitrix_id,
            lead_email: lead.lead_email,
            propd_contacts_pk_id: lead.propd_contacts_pk_id,
            mailerlite_pushed: lead.mailerlite_pushed,
            res_jp_ref: lead.res_jp_ref,
            res_web_ref: lead.res_web_ref,
            res_listing_type: lead.res_listing_type,
            res_property_type: lead.res_property_type,
            res_branch: lead.res_branch,
            res_price: lead.res_price,
            res_bathrooms: lead.res_bathrooms,
            res_bedrooms: lead.res_bedrooms,
            res_suburb: lead.res_suburb,
            res_suburb_search: lead.res_suburb_search,
            res_agent: lead.res_agent || "",
            res_agent_full_name: lead.res_agent_full_name,
            res_agent_2: lead.res_agent_2 || "",
            res_agent_full_name2: lead.res_agent_full_name2,
            res_agent_3: lead.res_agent_3 || "",
            res_agent_full_name3: lead.res_agent_full_name3,
            res_agent_4: lead.res_agent_4 || "",
            res_agent_full_name4: lead.res_agent_full_name4,
            contact_title: lead.contact_title,
            contact_first_name: lead.contact_first_name,
            contact_last_name: lead.contact_last_name,
            contact_cell_number: lead.contact_cell_number,
            contact_email: lead.contact_email,
            res_contact_id: lead.contact_email,
          });

        for (let cta of ctas.data.data) {
          let localcta = await dbcrm.crm_lead_ctas.get({
            pk_id: cta.pk_id,
          });
          if (localcta == undefined) {
            let newCTA = await dbcrm.crm_lead_ctas
              .add({
                pk_id: cta.pk_id,
                clients_pk_id: cta.clients_pk_id,
                branches_pk_id: cta.branches_pk_id,
                leads_pk_id: cta.leads_pk_id,
                data: cta.data,
                cta: cta.cta,
                created_date: cta.timestamp,
                timestamp: new Date(),
              })
              .catch((error) => console.error("Exception : ", error));
          }

          if (localcta) {
            let update_cta = await dbcrm.crm_lead_ctas
              .where("pk_id")
              .equals(cta.pk_id)
              .modify({
                pk_id: cta.pk_id,
                clients_pk_id: cta.clients_pk_id,
                branches_pk_id: cta.branches_pk_id,
                leads_pk_id: cta.leads_pk_id,
                data: cta.data,
                cta: cta.cta,
                created_date: cta.timestamp,
                timestamp: new Date(),
              })
              .catch((error) => console.error("Exception : ", error));
          }
        }

        for (let comment of comments.data.data) {
          let localcomment = await dbcrm.history.get({
            comments_pk_id: comment.comments_pk_id,
          });
          if (localcomment == undefined) {
            let newComment = await dbcrm.history
              .add({
                comments_pk_id: comment.comments_pk_id,
                leads_fk_id: comment.leads_fk_id,
                contacts_fk_id: comment.contacts_fk_id,
                viewing_fk_id: comment.viewing_fk_id,
                type: comment.type,
                title: comment.title,
                description: comment.description,
                author_email: comment.author_email,
                author_id: comment.author_id,
                created_date: comment.created_date,
                modified_date: comment.modified_date,
                deleted: comment.deleted,
                author: comment.author,
                history_type: comment.history_type,
              })
              .catch((error) => console.error("Exception : ", error));
          }
          if (localcomment) {
            let newComment = await dbcrm.history
              .where("comments_pk_id")
              .equals(comment.comments_pk_id)
              .modify({
                comments_pk_id: comment.comments_pk_id,
                leads_fk_id: comment.leads_fk_id,
                contacts_fk_id: comment.contacts_fk_id,
                viewing_fk_id: comment.viewing_fk_id,
                type: comment.type,
                title: comment.title,
                description: comment.description,
                author_email: comment.author_email,
                author_id: comment.author_id,
                created_date: comment.created_date,
                modified_date: comment.modified_date,
                deleted: comment.deleted,
                author: comment.author,
                history_type: comment.history_type,
              })
              .catch((error) => console.error("Exception : ", error));
          }
        }
      }
    }
    dbcrm.leads
      .where("deleted")
      .equals("1")
      .or("stage")
      .equals("lost")
      .or("stage")
      .equals("won")
      .delete()
      .then((res) => { })
      .catch((error) => {
        console.error("Exception : ", error);
      });
    if (response.data.data.length > 0) {
      actions.fetchLeads({
        commit,
      });
    }
  },
  fetchLeads: async ({ commit }) => {
    let data = [],
      branch_id = state.userData.active_branch,
      user_id = state.userData.client_id;
    // console.log("client_id fetch : ", user_id);

    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let response = [];
    // if (state.lead_search_string == null) {
    response = await dbcrm.leads
      .where("agent")
      .equals(user_id)
      .or("res_agent")
      .equals(user_id)
      .or("res_agent_2")
      .equals(user_id)
      .or("res_agent_3")
      .equals(user_id)
      .or("res_agent_4")
      .equals(user_id)
      .and((lead) => lead.branches_fk_id == branch_id)
      .and((lead) => {
        if (state.stage == "all") return lead;
        if (lead.stage == state.stage) return lead;
      })
      // .offset(state.leads.length)
      // .limit(10)
      .reverse()
      .sortBy("date_create");
    console.log("state.leads.length: ", state.leads.length);
    console.log("LEADS RESPONSE: ", response);

    // data = [...state.leads, ...response];
    data = response;
    commit("setLeads", data);
  },
  createLead: async ({ commit }, newLead) => {
    if (window.navigator.onLine) {
      const response = await axios.post("/api/v1/leads/create/", newLead);
      newLead.leads_pk_id = response.data.data.id;
    }
    if (!window.navigator.onLine) {
    }
    commit("newLead", newLead);
  },
  filterLeads: async ({ commit }) => {
    let data = [],
      branch_id = state.userData.active_branch,
      user_id = state.userData.client_id;

    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let response = [];
    if (state.lead_search_string == null) {
      response = await dbcrm.leads
        .where("agent")
        .equals(user_id)
        .or("res_agent")
        .equals(user_id)
        .or("res_agent_2")
        .equals(user_id)
        .or("res_agent_3")
        .equals(user_id)
        .or("res_agent_4")
        .equals(user_id)
        .and((lead) => lead.branches_fk_id == branch_id)
        .and((lead) => {
          if (state.stage == "all") return lead;
          if (lead.stage == state.stage) return lead;
          if (lead.lead_type.toLowerCase() == state.stage) return lead;
        })
        // .offset(state.leads.length)
        // .limit(10)
        .reverse()
        .sortBy("date_create");
      // console.log("filter state.leads.length: ", state.leads.length);
      // console.table("filter LEADS DATA: ", response);
      // data = [...state.leads, ...response];
    } else {
      response = await dbcrm.leads
        .where("agent")
        .equals(user_id)
        .or("res_agent")
        .equals(user_id)
        .or("res_agent_2")
        .equals(user_id)
        .or("res_agent_3")
        .equals(user_id)
        .or("res_agent_4")
        .equals(user_id)
        .and((lead) => lead.branches_fk_id == branch_id)
        .toArray();
      const leadsMap = response.map((lead) => [
        `${lead.leads_pk_id} ${lead.contact_first_name} ${lead.contact_last_name} ${lead.contact_email} ${lead.contact_cell_number}`.toLowerCase(),
        lead,
      ]);
      let map = new Map(leadsMap);
      let string = `*${state.lead_search_string}*`.toLowerCase();
      for (let key of map.keys()) {
        if (new RegExp("^" + string.replace(/\*/g, ".*") + "$").test(key)) {
          data.push(map.get(`${key}`.toLowerCase()));
        }
      }
      // console.log("state.lead_search_string data: ", state.lead_search_string);
      // console.log("FILTER LEADS: ");
      // console.log("response b4 map: ", response);
      // console.log("MAPED DATA: ", data);
      response = data;
      actions.setLeadSearchString(
        {
          commit,
        },
        null
      );
    }

    // data = response;
    data = response;
    commit("setLeads", data);
  },
  setStage: ({ commit }, stage) => {
    commit("setStage", stage);
  },
  setLeads: ({ commit }, data) => {
    commit("setLeads", data);
  },
  setListingType: ({ commit }, listing_type) => {
    commit("setListingType", listing_type);
  },
  fetchComments: async ({ commit }, lead_id = null) => {
    let data = [];
    let response = [];
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    if (lead_id) {
      response = await dbcrm.history
        .where("leads_fk_id")
        .equals(lead_id)
        .reverse()
        .sortBy("created_date");
      // .toArray();
      data = response;
    }
    if (!lead_id) {
      response = await dbcrm.history
        .where("contacts_fk_id")
        .equals(localStorage.getItem("contact"))
        .reverse()
        .sortBy("created_date");
      // .toArray();
      data = response;
    }
    commit("setComments", data);
  },
  addComment: async ({ commit }, comment) => {
    let data = [];
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));

    let res = await axios.post(`/api/v1/pdms/notes/`, {
      data: comment,
      branch: state.branches.find(
        (branch) => branch.branches_pk_id == state.userData.active_branch
      ),
      user: state.userData,
      method: "POST",
    });

    let response = await axios.post(`/api/v1/comments/create/`, {
      leads_fk_id: comment.leads_fk_id,
      contacts_fk_id: comment.contacts_fk_id,
      viewing_fk_id: comment.viewing_fk_id,
      type: comment.type,
      title: comment.title,
      description: comment.description,
      author_email: comment.author_email,
      author_id: comment.author_id,
      created_date: comment.created_date,
      modified_date: comment.modified_date,
      deleted: comment.deleted,
      author: comment.author,
      history_type: comment.history_type,
    });

    console.log("Created note/action : ", res);
    console.log("Created comment : ", response);

    if (response.data.data) {
      let newComment = await dbcrm.history
        .add({
          comments_pk_id: `${response.data.data.id}`,
          leads_fk_id: comment.leads_fk_id,
          contacts_fk_id: comment.contacts_fk_id,
          viewing_fk_id: comment.viewing_fk_id,
          type: comment.type,
          title: comment.title,
          description: comment.description,
          author_email: comment.author_email,
          author_id: comment.author_id,
          created_date: comment.created_date,
          modified_date: comment.modified_date,
          deleted: comment.deleted,
          author: comment.author,
          history_type: comment.history_type,
        })
        .catch((error) =>
          console.error("Exception adding History item : ", error)
        );
    }
    let comments = [];
    data = comments;
    if (comment.leads_fk_id) {
      comments = await dbcrm.history
        .where("leads_fk_id")
        .equals(comment.leads_fk_id)
        .toArray()
        .catch((error) =>
          console.error("Exception getting lead comments : ", error)
        );
      data = comments;
    }
    if (comment.contacts_fk_id) {
      comments = await dbcrm.history
        .where("contacts_fk_id")
        .equals(comment.contacts_fk_id)
        .toArray()
        .catch((error) =>
          console.error("Exception getting contact comments : ", error)
        );
      data = comments;
    }
    commit("setComments", data);
  },
  setFocusLead: ({ commit }, id) => {
    commit("setFocusLead", id);
  },
  setBranch: ({ commit }, id) => {
    commit("setBranch", id);
  },
  setUser: ({ commit }, id) => {
    commit("setUser", id);
  },
  setClientsId: ({ commit }, id) => {
    commit("setClientsId", id);
  },
  fetchLeadCounts: async ({ commit }) => {
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let pending = await dbcrm.leads
      .where("agent")
      .equals(state.userData.client_id)
      .or("res_agent")
      .equals(state.userData.client_id)
      .or("res_agent_2")
      .equals(state.userData.client_id)
      .or("res_agent_3")
      .equals(state.userData.client_id)
      .or("res_agent_4")
      .equals(state.userData.client_id)
      .and((lead) => lead.branches_fk_id == state.userData.active_branch)
      .and((lead) => lead.stage == "pending")
      .toArray();
    let active = await dbcrm.leads
      .where("agent")
      .equals(state.userData.client_id)
      .or("res_agent")
      .equals(state.userData.client_id)
      .or("res_agent_2")
      .equals(state.userData.client_id)
      .or("res_agent_3")
      .equals(state.userData.client_id)
      .or("res_agent_4")
      .equals(state.userData.client_id)
      .and((lead) => lead.branches_fk_id == state.userData.active_branch)
      .and((lead) => lead.stage == "active")
      .toArray();
    let ignored = await dbcrm.leads
      .where("agent")
      .equals(state.userData.client_id)
      .or("res_agent")
      .equals(state.userData.client_id)
      .or("res_agent_2")
      .equals(state.userData.client_id)
      .or("res_agent_3")
      .equals(state.userData.client_id)
      .or("res_agent_4")
      .equals(state.userData.client_id)
      .and((lead) => lead.branches_fk_id == state.userData.active_branch)
      .and((lead) => lead.ignored == "1")
      .toArray();
    commit("setLeadCounts", {
      pending: pending.length,
      active: active.length,
      ignored: ignored.length,
    });
  },
  setBranches: async ({ commit }) => {
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let branches = await dbcrm.branches.toArray();
    commit("setBranches", branches);
  },
  setUserData: async ({ commit }) => {
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let userData = await dbcrm.user.toArray();
    commit("setUserData", userData[0]);
  },
  addLeadCTA: async ({ commit }, cta) => {
    if (window.navigator.onLine) {
      let response = await axios.post("/api/v1/ctas/", {
        lead_id: cta.lead_id,
        cta: cta.cta,
        data: cta.data,
        branch: cta.branch,
        user: cta.user,
      });
      if (response) {
        let dbcrm = DBCRM.connect();
        await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
        let newCTA = await dbcrm.crm_lead_ctas
          .add({
            pk_id: response.data.data.id,
            clients_pk_id: cta.user,
            branches_pk_id: cta.branch,
            leads_pk_id: cta.lead_id,
            data: cta.data,
            cta: cta.cta,
            created_date: new Date(),
            timestamp: new Date(),
          })
          .catch((error) => console.error("Exception : ", error));
      }
    }
  },
  fetchLeadCTAS: async ({ commit }, lead_id) => {
    // if (window.navigator.onLine){
    //   let response = await axios.get(`/api/v1/ctas/${lead_id}`);

    // }
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let ctas = await dbcrm.crm_lead_ctas
      .where("leads_pk_id")
      .equals(`${lead_id}`)
      .toArray();

    commit("setLeadCTAS", ctas);
  },
  fetchMatchResults: async ({ commit }, data) => {
    commit("setMatchResults", data.data);
  },
  getNewLeads: async ({ commit }) => {
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let response = await axios.get(
      `/api/v1/leads/user/${state.userData.client_id}/branch/${state.userData.active_branch}/`
    );
    return response.data;
  },
  setLeadSearchString: async ({ commit }, data) => {
    commit("setLeadSearchString", data);
  },
};
const mutations = {
  setLeads: (state, leads) => (state.leads = leads),
  setStage: (state, stage) => (state.stage = stage),
  setListingType: (state, listing_type) => (state.listing_type = listing_type),
  newLead: (state, lead) => state.leads.unshift(lead),
  setComments: (state, comments) => (state.comments = comments),
  setFocusLead: (state, id) => (state.focusLead = id),
  setBranch: (state, id) => (state.branch = id),
  setBranches: (state, branches) => (state.branches = branches),
  setUser: (state, id) => (state.user = id),
  setUserData: (state, userData) => (state.userData = userData),
  setClientsId: (state, id) => (state.clients_id = id),
  setLeadCounts: (state, leadCounts) => {
    state.pending_leads = leadCounts.pending;
    state.active_leads = leadCounts.active;
    state.ignored_leads = leadCounts.ignored;
  },
  setLeadCTAS: (state, leadsCTAS) => (state.leadsCTAS = leadsCTAS),
  setMatchResults: (state, data) => (state.matchResults = data),
  setLeadSearchString: (state, data) => (state.lead_search_string = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
