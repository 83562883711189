<template>
  <!-- Install Prompt for Android -->
  <div
    id="menu-install-pwa-android"
    class="menu menu-box-bottom menu-box-detached rounded-s"
  >
    <div class="boxed-text-l mt-4 pb-3">
      <img class="rounded-l mb-3" :src="cmrLogo" alt="img" width="90" />
      <h4 class="mt-3">Add CRM.live on your Home Screen</h4>
      <p>
        Install CRM.live on your home screen, and access it just like a regular
        app. It really is that simple!
      </p>
      <span
        class="pwa-install btn btn-s shadow-l text-uppercase font-900 bg-highlight mb-2"
        >Add to Home Screen</span
      ><br />
      <span
        @click="closeMenu()"
        class="pwa-dismiss close-menu color-gray-dark text-uppercase font-900 opacity-60 font-10 pt-2"
        >Maybe later</span
      >
      <div class="clear"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AndroidInstallPrompt",
  methods: {
    closeMenu() {
      let menus = document.querySelectorAll(".menu-active");
      console.log("hider : ", document.getElementById("menu-hider"));
      document.getElementById("menu-hider").classList.remove("menu-active");
      Array.from(menus).forEach((el) => {
        el.classList.remove("menu-active");
      });
    },
  },
  computed: {
    cmrLogo() {
      let host = window.location.hostname;
      return "https://" + host + "/mob/v3/images/icons/icon-128x128-nl.png";
    },
  },
};
</script>
<style scoped></style>
