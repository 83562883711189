<template lang="">
  <div
    id="network-connection"
    data-bs-dismiss="notification-3"
    data-delay="3500"
    data-autohide="true"
    class="notification notification-material"
    :class="connection == 0 ? 'bg-red-dark' : 'bg-green-dark'"
  >
    <h3
      class="font-20 color-white mb-2 pt-3 mx-2 toast-title"
      :class="connection == 0 ? 'text-left' : ''"
    >
      {{ message }}
    </h3>
    <p
      class="pb-1 mx-2 opacity-60 line-height-s font-13 toast-description"
      :class="connection == 0 ? 'text-left' : ''"
    >
      <span v-html="description"></span>
    </p>
  </div>
</template>
<script>
export default {
  name: "NetworkConnectionNotification",
  props: {
    description: String,
    connection: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    message() {
      if (this.connection == 1) {
        return "You are back online";
      }
      return "No internet connection detected";
    },
    description() {
      if (this.connection == 0) {
        return "Please check your network connection.";
      }
      return "";
    },
  },
};
</script>
<style scoped>
.text-left {
  text-align: left;
}
</style>
