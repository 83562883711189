<template>
  <!-- Install instructions for iOS -->
  <div
    id="menu-install-pwa-ios"
    class="menu menu-box-bottom menu-box-detached rounded-s"
  >
    <div class="boxed-text-xl mt-4 pb-3">
      <img class="rounded-l mb-3" :src="cmrLogo" alt="img" width="90" />
      <h4 class="mt-3">Add CRM.live on your Home Screen</h4>
      <p class="mb-0 pb-0">
        Install CRM.live, and access it like a regular app. Open your Safari
        menu and tap "Add to Home Screen".
      </p>
      <div class="clearfix pt-3"></div>
      <a
        @click="closeMenu"
        class="pwa-dismiss close-menu color-highlight text-uppercase font-700"
        >Maybe later</a
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "IosInstallPrompt",
  method: {
    closeMenu() {
      let menus = document.querySelectorAll(".menu-active");
      document.getElementById("menu-hider").classList.remove("menu-active");
      Array.from(menus).forEach((el) => {
        console.log("el : ", el);
        el.classList.remove("menu-active");
      });
    },
  },
  computed: {
    cmrLogo() {
      let host = window.location.hostname;
      return "https://" + host + "/mob/v3/images/icons/icon-128x128-nl.png";
    },
  },
};
</script>
<style scoped></style>
