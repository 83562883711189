<template>
  <div>
    <div
      id="menu-reset-index-database"
      :deal-id="focusLead"
      class="menu mx-auto menu-box-bottom menu-box-detached rounded-m"
    >
      <div class="menu-title row mb-0">
        <div class="col-10" style="display:flex!important;justify-content:center!important;padding:0;">
          <h3 style=" transform: translate(15%,55%);z-index: 0;position: absolute;">Reset Data</h3>
        </div>
        <div class="col-2">
          <span class="close-menu float-right mx-4 my-3 h6"
            ><i class="la la-times text-danger"></i
          ></span>
        </div>
        <p class="font-10 px-3 color-highlight mt-n2 mb-0 text-left">
          This will remove all data stored locally; sync will be required.
        </p>
      </div>

      <div class="divider divider-margins mb-2"></div>

      <div class="content mt-2 row px-4">
        <span
          class="btn btn-s mt-4 rounded-1 btn-full col-12 bg-brand text-white text-uppercase font-900"
          @click="resetIndexDB"
          :class="spinner == 1 ? 'spinner' : ''"
          >{{ btnTitle }}</span
        >
        <span
          class="btn btn-s mt-2 mb-2 rounded-1 btn-full col-12 border text-brand text-uppercase font-900"
          @click="closeMenu"
          >No</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DBCRM from "@/assets/scripts/indexDB/config.js";

export default {
  name: "ResetDBModal",
  props: {
    leads_pk_id: String,
  },
  data() {
    return {
      spinner: 0,
    };
  },
  computed: {
    ...mapGetters(["focusLead", "userData"]),
    btnTitle() {
      return this.spinner == 1 ? "Loading..." : "Yes";
    },
  },
  methods: {
    ...mapActions(["addComment", "updateLeadStatus"]),
    closeMenu() {
      let menus = document.querySelectorAll(".menu-active");
      document.getElementById("menu-hider").classList.remove("menu-active");
      Array.from(menus).forEach((el) => {
        console.log("el : ", el);
        el.classList.remove("menu-active");
      });
    },
    async resetIndexDB() {
      this.spinner = 1;
      let dbcrm = DBCRM.connect();
      await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
      await dbcrm.leads.clear();
      await dbcrm.contacts.clear();
      await dbcrm.referrals.clear();
      await dbcrm.tasks.clear();
      await dbcrm.history.clear();
      await dbcrm.viewings.clear();
      await dbcrm.crm_lead_ctas.clear();
      await dbcrm.notifications.clear();
      this.spinner = 0;
      this.$parent.toastTitle = "Success";
      this.$parent.toastDescription = "Local data cleared!";
      this.showToast();
      this.closeMenu();
    },
    showToast() {
      this.$parent.showToast();
    },
  },
};
</script>
<style scoped>
.text-left {
  text-align: left;
}
</style>
