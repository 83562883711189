'use strict'
import axios from "axios";

const session = {
    store: async () => {
        let session = await axios.get(`/api/session/`);
        return session.data;
    },
    updateBranch: async (req) => {
        let session = await axios.post(`/api/session/update/${req.user}/${req.branch}`);
        return session.data;
    },
    getBranches: async (req) => {
        let session = await axios.post(`/api/profile/${req}`);
        return session.data;
    },
    getUserData : async (id) => {
        let session = await axios.get(`/api/session/${id}`);
        return session.data;
    }
};
export default session;