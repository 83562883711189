import axios from "axios";
import DBCRM from "../../assets/scripts/indexDB/config.js";

const state = {
  allnotification: [],
};
const getters = {
  allNotifications: (state) => state.allnotification,
};
const actions = {
  syncNotifications: async ({ commit }, data) => {
    let user_id = data.user;
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let response = await axios.get(`/api/v1/notifications/${user_id}/`);
    let notifications = response.data.data;

    for (let notification of notifications) {
      let local = await dbcrm.notifications.get({
        pk_id: `${notification.pk_id}`,
      });
      if (local == undefined) {
        dbcrm.notifications.add({
          pk_id: notification.pk_id,
          clients_pk_id: notification.clients_pk_id,
          branches_pk_id: notification.branches_pk_id,
          contact_bitrix_id: notification.contact_bitrix_id,
          title: notification.title,
          description: notification.description,
          timestamp: notification.timestamp,
          data: notification.data,
          viewed: notification.viewed,
          viewed_time: notification.viewed_time,
        });
      }
      if (local) {
        dbcrm.notifications
          .where("pk_id")
          .equals(`${notification.pk_id}`)
          .modify({
            pk_id: notification.pk_id,
            clients_pk_id: notification.clients_pk_id,
            branches_pk_id: notification.branches_pk_id,
            contact_bitrix_id: notification.contact_bitrix_id,
            title: notification.title,
            description: notification.description,
            timestamp: notification.timestamp,
            data: notification.data,
            viewed: notification.viewed,
            viewed_time: notification.viewed_time,
          });
      }
    }

    actions.fetchNotifications(
      {
        commit,
      },
      {
        clients_pk_id: user_id,
      }
    );
  },
  fetchNotifications: async ({ commit }, data) => {
    let dbcrm = DBCRM.connect();
    await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
    let notifications = await dbcrm.notifications
      .where("clients_pk_id")
      .equals(data.clients_pk_id)
      .reverse()
      .sortBy("timestamp");
    // console.log("Notis : ", notifications)
    // console.log("Notis data : ", data)
    commit("setNotifications", notifications);
  },
};
const mutations = {
  setNotifications: (state, data) => (state.allnotification = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
