import {
  createRouter,
  createWebHistory
} from "vue-router";
// import HomeView from '../views/HomeView.vue'

const routes = [{
    path: "/",
    name: "home",
    // component: HomeView
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/leads",
    name: "leads",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/LeadsView.vue"),
  },
  {
    path: "/contacts",
    name: "contacts",

    component: () =>
      import( /* webpackChunkName: "about" */ "../views/ContactsView.vue"),
  },
  {
    path: "/tasks",
    name: "tasks",

    component: () =>
      import( /* webpackChunkName: "about" */ "../views/TasksView.vue"),
  },
  {
    path: "/viewings",
    name: "viewings",

    component: () =>
      import( /* webpackChunkName: "about" */ "../views/ViewingsView.vue"),
  },
  {
    path: "/calendar",
    name: "calendar",

    component: () =>
      import( /* webpackChunkName: "about" */ "../views/CalendarView.vue"),
  },
  {
    path: "/referrals",
    name: "referrals",

    component: () =>
      import( /* webpackChunkName: "about" */ "../views/ReferralsView.vue"),
  },
  {
    path: "/help",
    name: "help",

    component: () =>
      import( /* webpackChunkName: "about" */ "../views/HelpView.vue"),
  },
  {
    path: "/describe-contact",
    name: "describe-contact",

    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/DescribeContactView.vue"
      ),
  },
  {
    path: "/new-contact",
    name: "new-contact",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/CreateNewContactView.vue"
      ),
  },
  {
    path: "/edit-contact",
    name: "edit-contact",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/EditContactView.vue"
      ),
  },
  {
    path: "/new-referral",
    name: "new-referral",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/NewReferralView.vue"),
  },
  {
    path: "/find-a-branch",
    name: "find-a-branch",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/FindAbranch.vue"),
  },
  {
    path: "/perform",
    name: "perform",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/PerformanceView.vue"),
  },
  {
    path: "/new-lead",
    name: "new-lead",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/CreateNewLeadView.vue"),
  },
  {
    path: "/edit-lead",
    name: "edit-lead",
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/EditLeadView.vue"
      ),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import( /* webpackChunkName: "about" */ "../views/DashboardView.vue"),
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import( /* webpackChunkName: "about" */ "../views/ReportsView.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import( /* webpackChunkName: "about" */ "../views/AdminView.vue"),
  },
  {
    path: "/search",
    name: "FindLead",
    component: () => import( /* webpackChunkName: "about" */ "../views/SearchLeadView.vue"),
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;