"use strict";
import Dexie from "dexie";
const DBCRM = {
  connect: (version = 7) => {
    let dbcrm = new Dexie("crm.nucleus.live");
    dbcrm.version(version).stores({
      // Contacts Table
      contacts: `
                ++id,
                &pk_id, 
                clients_fk_id,
                introduction_agent, 
                created, 
                modified, 
                source,
                status,
                legal_basis,
                first_name,
                last_name,
                email,
                cell_number,
                home_number,
                work_number,
                postal_address,
                physical_address,
                birthday,
                contact_types,
                id_number,
                branch,
                active
                `,
      // Leads Table
      leads: `
            ++pk_id,
            &leads_pk_id, 
            assigned_by_id, 
            branches_fk_id, 
            category_id, 
            created_by_id, 
            timestamp, 
            date_create, 
            date_modify, 
            modify_by_id, 
            begin_date, 
            contact_id, 
            opportunity, 
            stage_id, 
            stage, 
            pdms_stage, 
            property_type, 
            listing_type, 
            bedrooms, 
            bathrooms, 
            lead_type, 
            suburb, 
            web_ref, 
            source, 
            contact_type, 
            contact_comments, 
            disclosure, 
            description, 
            ignored, 
            deleted, 
            propd_leads_pk_id, 
            source_ref, 
            branch, 
            agent, 
            active, 
            source_description, 
            residential, 
            holiday, 
            commercial, 
            development, 
            message, 
            contact, 
            profile, 
            client_id, 
            property_id, 
            price_from, 
            price_to, 
            meta, 
            team, 
            telephonic, 
            lead_status, 
            bitrix_id, 
            lead_email, 
            propd_contacts_pk_id, 
            mailerlite_pushed, 
            res_jp_ref, 
            res_web_ref, 
            res_listing_type, 
            res_property_type, 
            res_branch, 
            res_price, 
            res_bathrooms, 
            res_bedrooms, 
            res_suburb, 
            res_suburb_search, 
            res_agent, 
            res_agent_full_name, 
            res_agent_2, 
            res_agent_full_name2, 
            res_agent_3, 
            res_agent_full_name3, 
            res_agent_4, 
            res_agent_full_name4, 
            contact_title, 
            contact_first_name, 
            contact_last_name, 
            contact_cell_number, 
            contact_email, 
            res_contact_id
            `,
      contact_last_search: `
            ++id,
            &bitrix_id,
            name,
            last_name,
            email,
            timestamp
            `,
      crm_lead_ctas: `
            ++id,
            &pk_id,
            clients_pk_id,
            branches_pk_id,
            client_bitrix_id,
            deal_bitrix_id,
            data,
            cta,
            status,
            created_date,
            leads_pk_id,
            timestamp
            `,
      tasks: `
            ++pk_id,
            &tasks_pk_id,
            timestamp,
            deadline,
            created_date,
            description,
            creator,
            responsible,
            subStatus,
            responsible_id,
            title,
            leads_pk_id,
            modified_date,
            modified_by,
            deleted
            `,
      history: `
            ++pk_id,
            &comments_pk_id,
            leads_fk_id,
            contacts_fk_id,
            viewing_fk_id,
            type,
            title,
            description,
            author_email,
            author_id,
            created_date,
            modified_date,
            deleted,
            author,
            history_type
            `,
      message_queue: `
            ++pk_id,
            timestamp,
            author_id,
            data,
            action_type
            `,
      referrals: `
            ++pk_id,
            &id,
            contact_id,
            type,
            status,
            start_date,
            end_date,
            referred_by_agent,
            referred_by_branch,
            referred_to_agent,
            referred_to_branch,
            percentage_fee,
            value_fee,
            guid,
            deleted,
            referral,
            contact
            `,
      viewings: `
            ++pk_id,
            &id,
            guid,
            time,
            date,
            status,
            web_ref,
            clients_fk_id,
            branches_fk_id,
            customer_email,
            customer_id,
            contact,
            agent_available_times,
            agent_proposed_times,
            selected_times,
            deleted,
            timestamp
            `,
      notifications: `
            ++id,
            &pk_id,
            clients_pk_id,
            branches_pk_id,
            contact_bitrix_id,
            title,
            description,
            created_date,
            timestamp,
            viewed,
            data,
            viewed_time
            `,
      user: `
            ++id,
            &clients_pk_id,
            &client_id,
            first_name,
            last_name,
            profile_pic,
            active_branch,
            default_branch,
            cell_number,
            email,
            super_global_admin_user
            `,
      branches: `
            ++id,
            &branches_pk_id,
            &propd_id,
            name
            `,
    });

    dbcrm.use({
      leads: {
        leads_pk_id: Number,
        agent: Number,
        res_agent: Number,
        res_agent_2: Number,
        res_agent_3: Number,
        res_agent_4: Number,
      },
      contacts: {
        introduction_agent: Number,
      },
      user: {
        clients_pk_id: Number,
        client_id: Number,
      },
      branches: {
        branches_pk_id: Number,
        propd_id: Number,
      },
    });
    return dbcrm;
  },
};
export default DBCRM;
// ++pk_id,
// &id,
// accepted,
// agent,
// agent_2_id,
// agent_3_id,
// agent_4_id,
// agent_id,
// cancelled,
// customer,
// customer_id,
// date_accepted,
// date_declined,
// date_initiated,
// declined,
// guid,
// listing_id,
// listing_type,
// request_count,
// requests
