<template lang="">
  <div
    id="notification-1"
    data-bs-dismiss="notification-3"
    data-delay="3500"
    data-autohide="true"
    class="notification notification-material bg-dark-dark"
  >
    <h1 class="font-20 color-white mb-2 pt-1 toast-title text-left">
      <i class="la la-check-circle font-17 me-2"></i>
      {{ title }}
    </h1>
    <p
      class="pb-1 pe-5 me-4 opacity-60 line-height-m font-13 toast-description text-left"
    >
      <span v-html="description"></span>
    </p>
  </div>
</template>
<script>
export default {
  name: "NotificationToast",
  props: {
    title: String,
    description: String,
  },
};
</script>
<style scoped>
.text-left {
  text-align: left;
}
</style>
