<template>
  <div
    style="height: 100vh"
    id="menu-efficiency-details"
    class="menu menu-box-bottom"
  >
    <div class="menu-title row mb-4">
      <div class="col-10" style="display:flex!important;justify-content:center!important;padding:0;">
        <h3 style=" transform: translate(15%,55%);z-index: 0;position: absolute;">Efficiency Details</h3>
      </div>
      <div class="col-2">
        <span @click="closeMenu" class="close-menu float-right mx-4 my-3 h6"
          ><i class="la la-times text-danger"></i
        ></span>
      </div>
    </div>
    <div class="divider divider-margins"></div>

    <div class="content mb-0">
      <div class="row mb-n3">
        <div class="col-6 pe-2">
          <div
            class="card card-style gradient-yellow shadow-bg shadow-bg-m mx-0 mb-3 text-left"
            data-card-height="130"
          >
            <div class="card-top p-3">
              <h5 class="color-white d-block font-15 pt-1">Pending</h5>
            </div>
            <div class="card-bottom p-3">
              <h1 class="color-white mb-n1 font-28">{{ pendingLeads }}</h1>
              <i class="color-white font-10 la la-arrow-up"></i>
              <span class="color-white font-10 font-700">1 New</span>
              <span class="color-white font-10 opacity-60">
                vs last 7 days</span
              >
            </div>
          </div>
        </div>
        <div class="col-6 ps-2">
          <div
            class="card card-style gradient-green shadow-bg shadow-bg-m mx-0 mb-3 text-left"
            data-card-height="130"
          >
            <div class="card-top p-3">
              <h5 class="color-white d-block font-15 pt-1">Active</h5>
            </div>
            <div class="card-bottom p-3">
              <h1 class="color-white mb-n1 font-28">{{ activeLeads }}</h1>
              <i class="color-white font-10 la la-arrow-up"></i>
              <span class="color-white font-10 font-700">3 More</span>
              <span class="color-white font-10 opacity-70">
                vs last 7 days</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-n3">
        <div class="card col-12 card-style mx-0 mb-3" data-card-height="170">
          <div class="d-flex p-3">
            <div>
              <h1 class="mb-n2 text-left">{{ perfomance }} %</h1>
              <span class="font-11">Engagement Performance</span>
            </div>
            <div class="ms-auto align-self-center">
              <i class="color-red-dark la la-angle-down font-18"></i>
            </div>
          </div>
          <div class="card-bottom">
            <div
              class="chart-container ms-n2 mb-n2"
              style="width: 110%; height: 120px"
            >
              <canvas class="graph" id="userReachStats" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DBCRM from "@/assets/scripts/indexDB/config.js";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "EfficiencyDetailsModal",
  props: {},
  data() {
    return {
      activeLeads: 0,
      pendingLeads: 0,
      perfomance: 0,
    };
  },
  computed: {
    ...mapGetters(["userData"]),
  },
  methods: {
    // ...mapActions(["getWeekDates"]),
    getWeekDates() {
      let now = new Date();
      let dayOfWeek = now.getDay();
      let numDay = now.getDate();

      let start = new Date(now);
      start.setDate(numDay - dayOfWeek);
      start.setHours(0, 0, 0, 0);

      let end = new Date(now);
      end.setDate(numDay + (6 - dayOfWeek));
      end.setHours(23, 59, 59, 59);

      return [start, end];
    },
    closeMenu() {
      let menus = document.querySelectorAll(".menu-active");
      Array.from(menus).forEach((el) => {
        el.classList.remove("menu-active");
      });
    },
    async getPerfomanceStats() {
      let dbcrm = DBCRM.connect();
      await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
      let user_id = this.userData.client_id;
      let branch_id = this.userData.active_branch;
      // let [start, end] = this.getWeekDates();
      let pending = await dbcrm.leads
        .where("agent")
        .equals(user_id)
        .or("res_agent")
        .equals(user_id)
        .or("res_agent_2")
        .equals(user_id)
        .or("res_agent_3")
        .equals(user_id)
        .or("res_agent_4")
        .equals(user_id)
        .filter((lead) => {
          let leaddate = new Date(lead.date_create);
          let date = new Date();
          date.setHours(0, 0, 0, 0);
          let seven_days = date.setDate(date.getDate() - 7);
          let branch = lead.branches_fk_id == `${branch_id}`;
          let status = lead.stage == "pending";
          let days = leaddate >= seven_days;
          console.log(
            "Every :",
            [branch, status, days].every((val) => val === true)
          );
          if ([branch, status, days].every((val) => val === true)) return lead;
        })
        .toArray();
      let active = await dbcrm.leads
        .where("agent")
        .equals(user_id)
        .or("res_agent")
        .equals(user_id)
        .or("res_agent_2")
        .equals(user_id)
        .or("res_agent_3")
        .equals(user_id)
        .or("res_agent_4")
        .equals(user_id)
        .filter((lead) => {
          let leaddate = new Date(lead.date_create);
          let date = new Date();
          date.setHours(0, 0, 0, 0);
          let seven_days = date.setDate(date.getDate() - 7);
          let branch = lead.branches_fk_id == `${branch_id}`;
          let status = lead.stage == "active";
          let days = leaddate >= seven_days;
          if ([branch, status, days].every((val) => val === true)) return lead;
        })
        .toArray();
      this.pendingLeads = pending.length;
      this.activeLeads = active.length;
      this.perfomance =
        (this.activeLeads / (this.activeLeads + this.pendingLeads)) * 100;
      console.log("pending pending  : ", pending.length);
      console.log("active active  : ", active.length);
      console.log("perfomance perfomance  : ", this.perfomance);
    },
  },
};
</script>
<style scoped>
.text-left {
  text-align: left;
}
/* #menu-efficiency-details {
  background-color: black !important;
} */
</style>
