import axios from "axios";
import DBCRM from "../../assets/scripts/indexDB/config.js";

const state = {
    tasks: [],
    filter: 'today',
    pending_tasks: 0,
    almost_due_tasks: 0,
    overdue_tasks: 0
};
const getters = {
    allTasks: (state) => state.tasks,
    currentfilter: (state) => state.filter,
    almost_due_tasks: (state) => state.almost_due_tasks,
    overdue_tasks: (state) => state.overdue_tasks,
    pending_tasks: (state) => state.pending_tasks,
};
const actions = {
    syncTasks: async ({
        commit
    }, ids) => {
        let user_id = ids.user;
        let dbcrm = DBCRM.connect();
        await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
        let response = await axios.get(
            `/api/v1/tasks/user/${user_id}`
        );
        for (let task of response.data.data) {
            let local = await dbcrm.tasks.get({
                tasks_pk_id: task.tasks_pk_id
            });
            if (local == undefined) {
                dbcrm.tasks
                    .add({
                        tasks_pk_id: task.tasks_pk_id,
                        deadline: task.deadline,
                        created_date: task.created_date,
                        description: task.description,
                        creator: task.creator,
                        responsible: task.responsible,
                        subStatus: task.subStatus,
                        responsible_id: task.responsible_id,
                        title: task.title,
                        leads_pk_id: task.leads_pk_id,
                        modified_date: task.modified_date,
                        modified_by: task.modified_by,
                        deleted: task.deleted,
                    })
                    .catch((error) => {
                        console.error("Adding leads error : ", error);
                    });
            }
            if (local) {
                dbcrm.tasks.where("tasks_pk_id").equals(task.tasks_pk_id).modify({
                    tasks_pk_id: task.tasks_pk_id,
                    deadline: task.deadline,
                    created_date: task.created_date,
                    description: task.description,
                    creator: task.creator,
                    responsible: task.responsible,
                    subStatus: task.subStatus,
                    responsible_id: task.responsible_id,
                    title: task.title,
                    leads_pk_id: task.leads_pk_id,
                    modified_date: task.modified_date,
                    modified_by: task.modified_by,
                    deleted: task.deleted,
                });
            }
        }
        dbcrm.tasks
            .where("deleted")
            .equals("1")
            .delete()
            .then((res) => {
                // console.log("Result : ", res);
            })
            .catch((error) => {
                console.error("Exception : ", error);
            });
        actions.filterTasks({
            commit
        }, user_id);
    },
    fetchTasks: async ({
        commit
    }, user_id) => {
        let data = [];
        let dbcrm = DBCRM.connect();
        await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
        let response = await dbcrm.tasks.where({
            'creator': user_id
        }).sortBy('deadline');
        console.log("task response : ", response)
        data = response;
        commit("setTasks", data);
    },
    createTask: async ({
        commit
    }, new_task) => {
        if (window.navigator.onLine) {}
        if (!window.navigator.onLine) {}
    },
    filterTasks: async ({
        commit
    }, user_id) => {
        let data = [],
            response = [];
        let dbcrm = DBCRM.connect();
        await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
        if (state.filter == "this_week") {
            let [start, end] = actions.getWeekDates();
            response = await dbcrm.tasks.where({
                'creator': user_id,
            }).filter((task) => {
                if (new Date(task.deadline) <= end && new Date(task.deadline) >= start)
                    return task;
            }).sortBy('deadline');
        }
        if (state.filter == "today") {
            let [start, end] = actions.getTodayDates();
            response = await dbcrm.tasks.where({
                'creator': user_id,
            }).filter((task) => {
                if (new Date(task.deadline) <= end && new Date(task.deadline) >= start)
                    return task;
            }).sortBy('deadline');
        }
        if (state.filter == "next_week") {
            let [start, end] = actions.getNextWeekDates();
            response = await dbcrm.tasks.where({
                'creator': user_id,
            }).filter((task) => {
                if (new Date(task.deadline) <= end && new Date(task.deadline) >= start)
                    return task;
            }).sortBy('deadline');
        }
        if (state.filter == "all_time") {
            response = await dbcrm.tasks.where({
                'creator': user_id,
            }).sortBy('deadline');
        }
        if (state.filter == "this_month") {
            let [start, end] = actions.getMonthDate();
            response = await dbcrm.tasks.where({
                'creator': user_id,
            }).filter((task) => {
                if (new Date(task.deadline) <= end && new Date(task.deadline) >= start)
                    return task;
            }).sortBy('deadline');
        }

        data = response;
        commit("setTasks", data);
    },
    setFilter: ({
        commit
    }, filter) => {
        commit("setFilter", filter)
    },
    getWeekDates: () => {
        let now = new Date();
        let dayOfWeek = now.getDay();
        let numDay = now.getDate();

        let start = new Date(now);
        start.setDate(numDay - dayOfWeek);
        start.setHours(0, 0, 0, 0);

        let end = new Date(now);
        end.setDate(numDay + (6 - dayOfWeek));
        end.setHours(23, 59, 59, 59);

        return [start, end];
    },
    getTodayDates: () => {
        let now = new Date();
        let start = new Date(now);
        start.setHours(0, 0, 0, 0);

        let end = new Date(now);
        end.setHours(23, 59, 59, 59);
        return [start, end];
    },
    getNextWeekDates: () => {
        let now = new Date();
        let dayOfWeek = now.getDay();
        let numDay = now.getDate();
        let start = new Date(now);
        start.setDate(numDay + (7 - dayOfWeek));
        start.setHours(0, 0, 0, 0);

        let end = new Date(now);
        end.setDate(numDay + (13 - dayOfWeek));
        end.setHours(23, 59, 59, 59);

        return [start, end];
    },
    getMonthDate: () => {
        const now = new Date();
        const start = new Date(now.getFullYear(), now.getMonth(), 1);
        start.setHours(0, 0, 0, 0);

        const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        end.setHours(23, 59, 59, 59);

        return [start, end];
    },
    fetchTaskCounts: async ({
        commit
    }, user_id) => {
        let dbcrm = DBCRM.connect();
        await dbcrm.open().catch((err) => console.log("DB Exception: ", err));
        let pending_tasks = await dbcrm.tasks.where('creator').equals(user_id).and(task => task.subStatus == 'pending').toArray();
        let [start, end] = actions.getTodayDates();
        let almost_due_tasks = await dbcrm.tasks.where({
            'creator': user_id,
        }).filter((task) => {
            if (new Date(task.deadline) <= end && new Date(task.deadline) >= start)
                return task;
        }).sortBy('deadline');
        let overdue_tasks = await dbcrm.tasks.where('creator').equals(user_id).and(task => task.subStatus == 'overdue').toArray();

        commit("setTaskCounts", {
            pending_tasks: pending_tasks.length,
            almost_due_tasks: almost_due_tasks.length,
            overdue_tasks: overdue_tasks.length
        })
    }
};
const mutations = {
    setTasks: (state, tasks) => (state.tasks = tasks),
    setFilter: (state, filter) => (state.filter = filter),
    setTaskCounts: (state, taskCounts) => {
        state.pending_tasks = taskCounts.pending_tasks;
        state.almost_due_tasks = taskCounts.almost_due_tasks;
        state.overdue_tasks = taskCounts.overdue_tasks;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};